.Artery_banner_sec .banner_con{ background-image: url('https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/artery_banner_chrctr_img.webp'); background-position: right 15% bottom -40px;}
.Artery_banner_sec .banner_con_text {width: auto;}
.Artery_banner_sec .banner_con_text1 h1{ font-size:91px;  line-height: 96px;}
.Artery_banner_sec .banner_con {
    padding: 130px 0 ;
    min-height: auto;
}

@media only screen and (max-width:1360px){
    .Artery_banner_sec .banner_con_text1 h1 {
        font-size: 75px;line-height: 87px;
    }
}

@media only screen and (max-width:1199px){

    .Artery_banner_sec .banner_con{background:url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/artery_banner_chrctr_img.webp);background-position:center;background-repeat:no-repeat;background-size: 85%; min-height: inherit;padding-top: 66%;padding-bottom: 0;}

    .Artery_banner_sec .banner_con_text1 h1 {
        
            font-size: 42px;
            line-height: 65px;
            
        
    }

    .Artery_banner_sec .banner_con_text1 h1 br{ display: none;}
   

}

@media only screen and (max-width: 767px){
   
    .Artery_banner_sec .banner_con_text1 h1 {
        font-size: 37px;
        line-height: 47px;
    }
    .Artery_banner_sec .banner_con_text {padding: 8px;  padding-bottom: 10px;}
}


@media only screen and (max-width: 599px){
    /* .Artery_banner_sec .banner_con{padding-top: 52%;} */
    /* .Artery_banner_sec .banner_con_text1 h1 {
        font-size: 45px;
        line-height: 49px;
    } */
}

@media only screen and (max-width: 499px){

    .Artery_banner_sec .banner_con_text1 h1 {
        font-size: 32px;
        line-height: 41px;
    }


    
}


