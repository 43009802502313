.about_banner .banner_con{background-image:url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/about_image1.png);padding:104px 0 127px 0;background-position:right 110px bottom}
.about_banner .banner_con_text1 h1{font-size:76px;line-height:89px}
.about_block2{background-image:url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/sub_bg1.png),url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/sub_bg2.png),url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/pharmagenomics_block2_green_bg.webp);background-repeat:no-repeat;background-position:right top,left bottom,center center;background-size:auto,auto,cover;padding:80px 0}
.about_block2_subwrapper{position:relative;padding-left:470px}
.about_block2 .textwrapper h2, .about_block2 .textwrapper p{color: #fff;}
.about_block2_subwrapper .roundimage_imgmainwrapper{position:absolute;left:0;top:50%;transform:translateY(-50%)}
.about_block3 .textwrapper h2, .about_block3 .textwrapper p{color: #fff;}
.about_block3 .textandimagewrapper{background-image:url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/pharmagenomics_block3_blue_bg.webp);background-size:60% 100%}
.about_block3{background-image:url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/about_image2.jpg)}

.about_block2 .roundimage_imgmainwrapper{background: rgba(255,255,255,0.3);}
.about_block2 .roundimage_imgsubwrapper{background: rgba(255,255,255,0.3);}


@media only screen and (max-width: 1670px) {
.about_banner .banner_con{background-position:right 20px bottom}
}
@media only screen and (max-width: 1530px) {
.about_block3{background-position:right -120px top}
}
@media only screen and (max-width: 1366px) {
.about_block3{background-position:right -210px top}
}
@media only screen and (max-width: 1360px) {
.about_banner .banner_con_text1 h1{font-size:65px;line-height:77px}
.about_banner .banner_con{padding:133px 0 127px}
}
@media only screen and (max-width: 1199px) {
.about_banner .banner_con{padding:0;background-size:75%;padding-top:52%;background-position:center top 29px}
.about_banner .banner_con_text1 h1{font-size:42px;line-height:65px}
.about_banner .banner_con_text1 h1 br{display:none}
.about_block2_subwrapper{padding:0}
.about_block2_subwrapper .roundimage_imgmainwrapper{position:inherit;left:inherit;top:inherit;transform:inherit;margin:0 auto;margin-bottom:30px}
.about_block2{padding:30px 0}
.about_block2 .textwrapper h2 br{display:none}
.about_block3{background-position:right top;background-size:100%;padding-top:63%;background-color:#0c1f70;padding-bottom:2%}
.about_block3 .textandimagewrapper{background:none;padding:0}
}
@media only screen and (max-width: 767px) {
.about_banner .banner_con_text1 h1{font-size:32px;line-height:41px}
.about_block3{padding-top:63%}
.about_block2{padding-bottom:10px}
}
@media only screen and (max-width: 560px) {
.about_banner .banner_con_text1 h1 span{display:block}
}
@media only screen and (max-width: 480px) {
.about_block2 .roundimage_imgwrapper{height:230px;width:230px}
.about_block2 .roundimage_imgsubwrapper{height:270px;width:270px}
.about_block2 .roundimage_imgmainwrapper{height:300px;width:300px}
}
