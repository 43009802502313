.xwrap_main_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapbg.webp");
    background-repeat: no-repeat;
    background-size: cover;
  
}

.xwrap_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg.webp");
    background-repeat: no-repeat;
    background-position: left  bottom;
    padding: 35px 120px 20px 920px;
    color: white;
    position: relative;
}
.xwrap_wrap img{
    width: 350px;
    position: absolute;
    left: 450px;
    bottom: 20px;
}


.xwrap_content_wrap h3{
 font-size: 34px;
}

.xwrap_content_wrap p{
    font-size: 16px;
   padding-bottom: 23px;
}
.text_yellow{
    color: rgb(231,225,11);
}

@media screen and (max-width:1899px) {



.xwrap_wrap img{
    width: 350px;
    position: absolute;
    left: 450px;
    bottom: 20px;
}


.xwrap_content_wrap h3{
 font-size: 32px;
}

.xwrap_content_wrap p{
    font-size: 15.7px;
   padding-bottom: 23px;
}


    
    
}


@media screen and (max-width:1799px) {



.xwrap_wrap img{
    width: 350px;
    position: absolute;
    left: 450px;
    bottom: 20px;
}


.xwrap_content_wrap h3{
 font-size: 32px;
}

.xwrap_content_wrap p{
    font-size: 14.9px;
   padding-bottom: 23px;
}


    
    
}


@media screen and (max-width:1699px) {



.xwrap_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg.webp");
    background-repeat: no-repeat;
    background-position: left -150px  bottom;
    padding: 35px 100px 0px 770px;
    color: white;
    position: relative;
}
.xwrap_wrap img{
    width: 350px;
    position: absolute;
    left: 350px;
    bottom: 20px;
}


.xwrap_content_wrap h3{
 font-size: 33px;
}

.xwrap_content_wrap p{
    font-size: 15.7px;
   padding-bottom: 23px;
}


    
    
}

@media screen and (max-width:1599px) {



.xwrap_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg.webp");
    background-repeat: no-repeat;
    background-position: left -200px  bottom;
    padding: 35px 80px 20px 720px;
    color: white;
    position: relative;
}
.xwrap_wrap img{
    width: 350px;
    position: absolute;
    left: 330px;
    bottom: 20px;
}


.xwrap_content_wrap h3{
 font-size: 32px;
}

.xwrap_content_wrap p{
    font-size: 15px;
   padding-bottom: 23px;
}


    
    
}

@media screen and (max-width:1499px) {



.xwrap_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg.webp");
    background-repeat: no-repeat;
    background-position: left -250px  bottom;
    padding: 35px 60px 20px 680px;
    color: white;
    position: relative;
}
.xwrap_wrap img{
    width: 350px;
    position: absolute;
    left: 250px;
    bottom: 20px;
}


.xwrap_content_wrap h3{
 font-size: 32px;
}

.xwrap_content_wrap p{
    font-size: 14.9px;
   padding-bottom: 23px;
}


    
    
}

@media screen and (max-width:1399px) {



.xwrap_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg.webp");
    background-repeat: no-repeat;
    background-position: left -350px  bottom;
    padding: 35px 60px 20px 570px;
    color: white;
    position: relative;
}
.xwrap_wrap img{
    width: 350px;
    position: absolute;
    left: 150px;
    bottom: 20px;
}


.xwrap_content_wrap h3{
 font-size: 32px;
}

.xwrap_content_wrap p{
    font-size: 14.9px;
   padding-bottom: 23px;
}


    
    
}


@media screen and (max-width:1299px) {



.xwrap_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg.webp");
    background-repeat: no-repeat;
    background-position: left -350px  bottom;
    padding: 35px 50px 20px 570px;
    color: white;
    position: relative;
}
.xwrap_wrap img{
    width: 350px;
    position: absolute;
    left: 100px;
    bottom: 20px;
}


.xwrap_content_wrap h3{
 font-size: 32px;
}

.xwrap_content_wrap p{
    font-size: 14px;
   padding-bottom: 23px;
}


    
    
}

@media screen and (max-width:1199px) {



.xwrap_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg.webp");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 1200px;
    padding: 0;
    padding: 1090px 40px 20px 40px;
    
}
.xwrap_wrap img{
    width: 400px;
    position: absolute;
    left: 650px;
    top: 600px;
}


.xwrap_content_wrap h3{
 font-size: 34px;
}

.xwrap_content_wrap p{
    font-size: 16px;
   padding-bottom: 23px;
}


    
    
}

@media screen and (max-width:1099px) {



.xwrap_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg.webp");
    background-repeat: no-repeat;
    background-position: center top;
    padding: 0;
    padding: 990px 40px 20px 40px;
    background-size: 1100px;
    
}
.xwrap_wrap img{
    width: 400px;
    position: absolute;
    left: 500px;
    top: 540px;
}


.xwrap_content_wrap h3{
 font-size: 34px;
}

.xwrap_content_wrap p{
    font-size: 16px;
   padding-bottom: 23px;
}


    
    
}

@media screen and (max-width:999px) {



.xwrap_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg.webp");
    background-repeat: no-repeat;
    background-position: center top;
    padding: 0;
    padding: 920px 40px 20px 40px;
    background-size: 1000px;
    
}
.xwrap_wrap img{
    width: 350px;
    position: absolute;
    left: 450px;
    top: 500px;
}


.xwrap_content_wrap h3{
 font-size: 34px;
}

.xwrap_content_wrap p{
    font-size: 16px;
   padding-bottom: 23px;
}


    
    
}

@media screen and (max-width:899px) {



.xwrap_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg.webp");
    background-repeat: no-repeat;
    background-position: center top;
    padding: 0;
    padding: 820px 40px 20px 40px;
    background-size: 900px;
    
}
.xwrap_wrap img{
    width: 300px;
    position: absolute;
    left: 420px;
    top: 460px;
}


.xwrap_content_wrap h3{
 font-size: 34px;
}

.xwrap_content_wrap p{
    font-size: 16px;
   padding-bottom: 23px;
}


    
    
}


@media screen and (max-width:799px) {



.xwrap_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg.webp");
    background-repeat: no-repeat;
    background-position: center top;
    padding: 0;
    padding: 820px 40px 20px 40px;
    
}
.xwrap_wrap img{
    width: 350px;
    position: absolute;
    left: 350px;
    top: 400px;
}


.xwrap_content_wrap h3{
 font-size: 34px;
}

.xwrap_content_wrap p{
    font-size: 16px;
   padding-bottom: 23px;
}


    
    
}

@media screen and (max-width:699px) {



.xwrap_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg.webp");
    background-repeat: no-repeat;
    background-position: center top;
    padding: 0;
    padding: 640px 40px 20px 40px;
    background-size: 700px;
    
}
.xwrap_wrap img{
    width: 300px;
    position: absolute;
    left: 300px;
    top: 280px;
}


.xwrap_content_wrap h3{
 font-size: 34px;
}

.xwrap_content_wrap p{
    font-size: 16px;
   padding-bottom: 23px;
}


    
    
}


@media screen and (max-width:599px) {



.xwrap_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg.webp");
    background-repeat: no-repeat;
    background-size: 600px;
    background-position: center top;
    padding: 0;
    padding: 560px 40px 20px 40px;
    
}
.xwrap_wrap img{
    width: 270px;
    position: absolute;
    left: 230px;
    top: 250px;
}


.xwrap_content_wrap h3{
 font-size: 34px;
}

.xwrap_content_wrap p{
    font-size: 16px;
   padding-bottom: 23px;
}


    
    
}

@media screen and (max-width:499px) {



.xwrap_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg.webp");
    background-repeat: no-repeat;
    background-size: 600px;
    background-position: center top;
    padding: 0;
    padding: 550px 30px 10px 30px;
    
}
.xwrap_wrap img{
    width: 250px;
    position: absolute;
    left: 150px;
    top: 250px;
}


.xwrap_content_wrap h3{
 font-size: 34px;
}

.xwrap_content_wrap p{
    font-size: 16px;
   padding-bottom: 23px;
}


    
    
}


@media screen and (max-width:399px) {



.xwrap_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/xwrapimg.webp");
    background-repeat: no-repeat;
    background-size: 500px;
    background-position: center top;
    padding: 0;
    padding: 450px 20px 10px 20px;
    
}
.xwrap_wrap img{
    width: 180px;
    position: absolute;
    left: 100px;
    top: 250px;
}


.xwrap_content_wrap h3{
 font-size: 34px;
}

.xwrap_content_wrap p{
    font-size: 16px;
   padding-bottom: 23px;
}


    
    
}