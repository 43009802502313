.testimo_bnnr_sec .banner_wrpr_bg1 {
    background: url(https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/pharmaGenomics_bnnr_hex.webp),url(https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/pharma_hex_small1.webp),url(https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/pharma_hex_small1.webp);
    background-position: right 43% bottom 21px, top left 26%,top 38% left 94%;;
    background-repeat: no-repeat;
    min-height: auto;
}

.testimo_bnnr_sec .banner_con{background-image: url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/tesimo_bannr.webp);
    padding: 165px 0 132px;
    background-position: right 180px bottom 0px;}

 @media only screen and (max-width: 1460px){
    .testimo_bnnr_sec .banner_con_text1 h1 {
        font-size: 85px;
        line-height: 77px;
    }
    .testimo_bnnr_sec .banner_con {
             background-position: right 48px bottom -41px;
        }    
    }
    
    @media only screen and (max-width: 1199px){
        .testimo_bnnr_sec .banner_con {
            padding: 0;
            background-size: 75%;
            padding-top: 55%;
            background-position: center top 0;
        }
    }

    @media only screen and (max-width: 1199px){
  
        .testimo_bnnr_sec .banner_con_text1 h1 {
            font-size: 42px;
            line-height: 65px;
        }
    }

    /* @media only screen and (max-width: 767px){
  
        .testimo_bnnr_sec .banner_con_text1 h1 {
            font-size: 32px;
            line-height: 45px;
        }
    } */
    
