.amnion_main_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/aniombg.webp");
    background-repeat: no-repeat;
    background-size: cover;
 
}
.amnion_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/aniomimg.webp");
    background-repeat: no-repeat;
    background-position: right bottom;
    padding: 40px 870px 70px 230px;
}

.amnion_content_wrap h2{
    font-size: 35px;
    color: rgb(10,29,113);
    line-height: 40px;

}
.amnion_content_wrap p{
    font-size: 19px;
    font-weight: 600;
   
    

}
.content_one{
    padding-top: 20px;
}
.text_blue{
    color: rgb(10,29,113); 
}

.content_two{
    padding-top: 30px;
}

@media screen and (max-width:1899px) {

.amnion_wrap{
   
    padding: 40px 800px 70px 220px;
    background-position: right -50px  bottom;
}

.amnion_content_wrap h2{
    font-size: 33px;
    color: rgb(10,29,113);
    line-height: 45px;

}
.amnion_content_wrap p{
    font-size: 19px;
    font-weight: 600;
   
    

}
.content_one{
    padding-top: 20px;
}
.text_blue{
    color: rgb(10,29,113); 
}

.content_two{
    padding-top: 30px;
}

    
}

@media screen and (max-width:1799px) {

.amnion_wrap{
   
    padding: 40px 800px 70px 220px;
    background-position: right -60px  bottom;
}

.amnion_content_wrap h2{
    font-size: 31px;
    color: rgb(10,29,113);
    line-height: 35px;

}
.amnion_content_wrap p{
    font-size: 18px;
    font-weight: 600;
   
    

}
.content_one{
    padding-top: 20px;
}
.text_blue{
    color: rgb(10,29,113); 
}

.content_two{
    padding-top: 30px;
}

    
}

@media screen and (max-width:1699px) {

.amnion_wrap{
   
    padding: 40px 800px 70px 220px;
    background-position: right -60px  bottom;
}

.amnion_content_wrap h2{
    font-size: 28px;
    color: rgb(10,29,113);
    line-height: 35px;

}
.amnion_content_wrap p{
    font-size: 17px;
    font-weight: 600;
   
    

}
.content_one{
    padding-top: 20px;
}
.text_blue{
    color: rgb(10,29,113); 
}

.content_two{
    padding-top: 30px;
}

    
}

@media screen and (max-width:1599px) {


.amnion_wrap{
   
    padding: 40px 650px 70px 200px;
    background-position: right -120px  bottom;
}

.amnion_content_wrap h2{
    font-size: 26px;
    color: rgb(10,29,113);
    line-height: 35px;

}
.amnion_content_wrap p{
    font-size: 16px;
    font-weight: 600;
   
    

}
.content_one{
    padding-top: 20px;
}
.text_blue{
    color: rgb(10,29,113); 
}

.content_two{
    padding-top: 30px;
}

    
}

@media screen and (max-width:1499px) {


.amnion_wrap{
   
    padding: 40px 600px 70px 150px;
    background-position: right -170px  bottom;
}

.amnion_content_wrap h2{
    font-size: 24px;
    color: rgb(10,29,113);
    line-height: 35px;

}
.amnion_content_wrap p{
    font-size: 17px;
    font-weight: 600;
   
    

}
.content_one{
    padding-top: 20px;
}
.text_blue{
    color: rgb(10,29,113); 
}

.content_two{
    padding-top: 30px;
}

    
}

@media screen and (max-width:1399px) {


.amnion_wrap{
   
    padding: 40px 630px 70px 130px;
    background-position: right -150px  bottom;
}

.amnion_content_wrap h2{
    font-size: 22px;
    color: rgb(10,29,113);
    line-height: 30px;

}
.amnion_content_wrap p{
    font-size: 16px;
    font-weight: 600;
   
    

}
.content_one{
    padding-top: 20px;
}
.text_blue{
    color: rgb(10,29,113); 
}

.content_two{
    padding-top: 30px;
}

    
}

@media screen and (max-width:1299px) {


.amnion_wrap{
   
    padding: 40px 630px 70px 130px;
    background-position: right -200px  bottom;
}

.amnion_content_wrap h2{
    font-size: 22px;
    color: rgb(10,29,113);
    line-height: 30px;

}
.amnion_content_wrap p{
    font-size: 15px;
    font-weight: 600;
   
    

}
.content_one{
    padding-top: 20px;
}
.text_blue{
    color: rgb(10,29,113); 
}

.content_two{
    padding-top: 30px;
}

    
}

@media screen and (max-width:1199px) {

    .amnion_main_wrap{
   margin-top: 0;
}
.amnion_wrap{
    background-size: 130%;
    padding: 680px 40px 40px 40px;
    background-position: center top;
    padding-top: 91%;
    background-position-x: -225px;
}

.amnion_content_wrap h2{
    font-size: 35px;
    color: rgb(10,29,113);
    line-height: 40px;

}
.amnion_content_wrap p{
    font-size: 19px;
    font-weight: 600;
   
    

}
.content_one{
    padding-top: 20px;
}
.text_blue{
    color: rgb(10,29,113); 
}

.content_two{
    padding-top: 30px;
}

    
}

@media screen and (max-width:799px) {
    .amnion_wrap{
        background-size: 700px;
        padding: 510px 40px 40px 40px;
        background-position: center top;
    }

}

@media screen and (max-width:699px) {


.amnion_wrap{
    background-size: 700px;
    padding: 510px 40px 40px 40px;
    background-position: center top;
}

.amnion_content_wrap h2{
    font-size: 30px;
    color: rgb(10,29,113);
    line-height: 35px;

}
.amnion_content_wrap p{
    font-size: 16px;
    font-weight: 600;
   
    

}
.content_one{
    padding-top: 20px;
}
.text_blue{
    color: rgb(10,29,113); 
}

.content_two{
    padding-top: 30px;
}

    
}



@media screen and (max-width:599px) {


.amnion_wrap{
    background-size: 600px;
    padding: 430px 40px 40px 40px;
    background-position: center top;
}

.amnion_content_wrap h2{
    font-size: 28px;
    color: rgb(10,29,113);
    line-height: 35px;

}
.amnion_content_wrap p{
    font-size: 16px;
    font-weight: 600;
   
    

}
.content_one{
    padding-top: 20px;
}
.text_blue{
    color: rgb(10,29,113); 
}

.content_two{
    padding-top: 30px;
}

    
}



@media screen and (max-width:499px) {

.amnion_wrap{
    background-size: 500px;
    padding: 360px 30px 30px 30px;
    background-position: center top;
}

.amnion_content_wrap h2{
    font-size: 24px;
    color: rgb(10,29,113);
    line-height: 33px;

}
.amnion_content_wrap p{
    font-size: 16px;
    font-weight: 600;
   
    

}
.content_one{
    padding-top: 20px;
}
.text_blue{
    color: rgb(10,29,113); 
}

.content_two{
    padding-top: 30px;
}

    
}

@media screen and (max-width:399px) {

.amnion_wrap{
    background-size: 500px;
    padding: 350px 20px 20px 20px;
    background-position: center top;
}

.amnion_content_wrap h2{
    font-size: 24px;
    color: rgb(10,29,113);
    line-height: 30px;

}
.amnion_content_wrap p{
    font-size: 16px;
    font-weight: 600;
   
    

}
.content_one{
    padding-top: 20px;
}
.text_blue{
    color: rgb(10,29,113); 
}

.content_two{
    padding-top: 30px;
}

    
}