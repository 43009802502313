body {
  margin: 0 !important;
   font-family: 'Poppins', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,h2,h3,h4,h5,h6,p{padding:0;margin:0}
:root {
    --font-bold: 800;
    --font-semibold: 600;
    --font-medium: 400;
    --font-regular: 300;



    /* ------------ blog colors ------------ */
  --blog-detail-left-bg:#049dcc;
  --blog-detail-left-image-bg:#ddd;
  --blog-detail-left-border:#049dcc;
  --blog-detail-right-bg:#049dcc;
  --blog-detail-right-border:#049dcc;
  --blog-detail-loadmore-block-bg:#065e78;
  --blog-detail-right-list-hoveractive-bg:#105093;
  --blog-detail-loadmore-btn-bg:#fff;
  --blog-list-bg:#28b4af;



  --blog-detail-right-smallblock-bg:#f0fbff;
  --blog-detail-right-list-hoveractive-bg:#096487;
  --blog-detail-right-active-title-color:#fff;
  --blog-detail-right-active-date-color:#fff;







/* ------------ Video gallery ------------ */


--video-gallery-mainvideo-border-color:#1f3699;
--video-gallery-dec-bg:#b4edf5;
--video-gallery-list-bg:#fff;
--video-gallery-list-border:#fff;
--video-card-bg:#fff;
--video-card-border-bg:#fff;
--video-card-shadow: 2px 2px 8px rgba(0,0,0,0.2);
--video-card-hover-bg:#b9eaf2;
--video-card-hover-shadow:2px 2px 8px rgba(0,0,0,0.4);


/* ------------ Image gallery ------------ */

--imggallery-main-img-bg:#b4eff3;

  /* ------------ Img Card colors ------------ */
  --img-card-padding: 14px;
  --img-card-bg: #fff;
  --img-card-border-radius: 10px;

  --img-card-img-bg: #fff;
  --img-card-img-bordercolor: #aba6a6;

  --img-card-title-fontsize: 24px;
  --img-card-title-color: #221c17;

  --img-card-desc-fontsize: 14px;
  --img-card-desc-color: #707172;

  /* For Hover */
  --img-card-hover-padding: 14px;
  --img-card-hover-bg: #c5eaf5;
  --img-card-border-hover-radius: 10px;





/* ------------ Image testimonials colors ------------ */
--tm-card-bg:#fff;
--tm-card-border-bg:#fff;
--tm-card-hover-bg:#c1f4ff;
--tm-card-hover-border-bg:#92e7fa;

--tm-readMore-text-color:#0c1f70;
--tm-readMore-border-color:#0c1f70;
--tm-readMore-borderradius:35px;
--tm-readMore-bg:#fff;

--tm-readMore-hover-bg:#0c1f70;
--tm-readMore-hover-border-color:#0c1c64;
--tm-readMore-hover-text-color:#fff;

--tm-image-border-color:#92e7fa;

/* ------------ Image testimonials details colors ------------ */
 --tm-detail-border-color:#28b4af;
--tm-detail-dec-bg:#c1f4ff;

/* ------------- all loadmore button -------- */

--tm-loadmore-color:#0c1f70;
--tm-loadmore-border-color:#0c1f70;
--tm-loadmore-border-radius:40px;

--tm-loadmore-hover-bg:#0c1f70;
--tm-loadmore-hover-border-color:#0c1c64;
--tm-loadmore-hover-text-color:#fff;




  /* For Modal Style */
  --common-modalbox-border-color: #0c1f70;
  --audio-modal-max-width: 800px;
  --video-modal-max-width: 800px;
  --youtubeVideo-modal-max-width: 800px;

  --audio-bg: #ccc;
  --audio-tag-bg: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(215, 215, 215, 1) 100%
  );
  --audio-tag-text-color: #000;
  --audio-tag-button-color: #ddd;
  --audio-box-shadow: 2px 2px 8px rgb(0 0 0 / 30%);
  --audio-border-width: 3px;
  --audio-border-color: #fff;
  --audio-border-radius: 40px;
  --close-btn-bg: #0c1f70;
  --close-btn-color: #fff;






}

.common_width{width:1490px;margin:0 auto}

.textwrapper h2{font-size:42px;line-height:50px;font-weight:var(--font-bold);color:#000;margin-bottom: 30px;}
.textwrapper p{font-size:18px;line-height:23px;font-weight:var(--font-regular);color:#000;margin-bottom: 25px;}
.textwrapper p:last-child{margin-bottom: 0;}
.textwrapper h3{font-weight: var(--font-semibold);font-size: 30px; color: #fff000;line-height: 34px;}
.block_listwrapper p{font-size:15px;line-height:18px;font-weight:var(--font-semibold);color:#000;position:relative;padding:7px 0;padding-left:30px}

.block_listwrapper p::after{content:'';height:13px;width:13px;border-radius:50%;background-color:#fff;position:absolute;left:0;top:7px}
.blue_bullet_point p::after{border:3px solid #07144e}
.green_bullet_point p::after{border:3px solid #2bb566}

.roundimage_imgmainwrapper{height:390px;width:390px;background:rgba(255,255,255,.1);border-radius:50%;overflow:hidden;display:flex;justify-content:center;align-items:center;}
.roundimage_imgsubwrapper{height:340px;width:340px;background:rgba(255,255,255,.1);border-radius:50%;overflow:hidden;display:flex;justify-content:center;align-items:center}
.roundimage_imgwrapper{height:270px;width:270px;border-radius:50%;overflow:hidden;display:flex;justify-content:center;align-items:center}

.gradient_btn a{background:rgb(29,188,231);background:linear-gradient(83deg,rgba(29,188,231,1) 0%,rgba(27,194,57,1) 100%);border:0;font-size:16px;padding:15px 25px;border-radius:5px;cursor:pointer;font-weight:var(--font-semibold);color:#FFF; display: block;text-decoration: none;}
.wh_btn {padding: 0; border: none;border-radius:5px;}
.wh_btn a{background-color:#fff;border:0;font-size:16px;padding:15px 25px;cursor:pointer;font-weight:var(--font-semibold);color:#000;display: block;text-decoration: none;border-radius:5px;}


.left_image_andtext{background-repeat:no-repeat;background-position:left top;position:relative;background-size:auto 100%}
.left_image_andtext .textandimagewrapper{background-repeat:no-repeat;background-position:left 100% top;padding:70px 0;position:relative;background-size:54.5% 100%}
.left_image_andtext .textandimagewrapper_padding{padding-left:49%}


.right_image_andtext{background-repeat:no-repeat;background-position:right top;position:relative;background-size:auto 100%}
.right_image_andtext .textandimagewrapper{background-repeat:no-repeat;background-position:left top;padding:70px 0;position:relative;background-size:54% 100%}
.right_image_andtext .textandimagewrapper_padding{padding-right:49%}


.common_banner_sec .banner_body{ background-color: #b4eafe; overflow: hidden;}
.common_banner_sec .banner_wrapper{ background: url('https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/hac_bannerBG2.webp'); background-size: cover;}

/* -------auto_fill--- */
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 35px #ffffff inset !important;
  /* background-color: #000 !important; */
  background-color: -internal-light-dark(rgb(255, 255, 255), rgba(70, 90, 126, 0.4))  !important ;
}
/* -------auto_fill--- */

@media only screen and (max-width: 1540px) {
  .common_width {
    width: 95% !important;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1380px) {
.textwrapper h2 {
    font-size: 36px;
    line-height: 41px;
}
}
@media only screen and (max-width: 1199px) {

.left_image_andtext .textandimagewrapper_padding, .right_image_andtext .textandimagewrapper_padding{padding:0}
.left_image_andtext .textandimagewrapper{padding: 0;}
/* .common_banner_sec .banner_con{min-height:inherit;padding-top:46%;padding-bottom: 0;} */
.banner_con_text{background:#d2f5fa;display:block;width:auto; padding:15px;margin-left:-3%;margin-right:-3%; padding-bottom: 25px; border-top: solid 5px #1bc23a;text-align: center;}
}
@media only screen and (max-width: 560px){
.textwrapper h2 {
    font-size: 32px;
    line-height: 40px;
}
/* .textwrapper p:last-child{margin-bottom: 25px;} */
.common_banner_sec .common_width {width:95% !important}
.common_width {
  width: 92% !important;
  margin: 0 auto;
}
}

 
/* -----------rd----------------------- */

.textwrapper p span {color:#fff600 ;}
.common_banner_sec .banner_con { padding: 88px 0 68px; min-height: auto; background-repeat: no-repeat; background-position: right bottom;}

.banner_con_text1 h1 {
 
  font-size: 107px;
  line-height: 110px;
  color: #0a1e72;
  font-weight: 800;
}
.banner_con_text1 h1 span {
  font-weight: 500;
  
}

.common_banner_sec .banner_wrpr_bg {
  background-image: url('https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/pharmaGenomics_bnnr_hex.webp'),url('https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/pharma_hex_small1.webp'),url('https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/Artery_banner_hexa_trans.webp');
  background-position:  left 53% bottom -46px, top left 26%,top 45% right 7%; background-repeat: no-repeat; min-height: auto;
}



.common_banner_sec .banner_wrpr_bg1{
  background:  url('https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/pharmaGenomics_bnnr_hex.webp'),url('https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/pharma_hex_small1.webp'),url('https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/pharma_hex_small1.webp') ;
  background-position:left 20px bottom 22px, top left 26%,top 100% left 37%; background-repeat: no-repeat; min-height: auto;
 ;
}

.common_banner_sec .banner_body{ background-color: #b4eafe; overflow: hidden;}
.common_banner_sec .banner_wrapper{ background: url('https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/hac_bannerBG2.webp'); background-size: cover;}




@media only screen and(max-width: 1199px) {
  .banner_con_text1 h1 {
    font-size: 50px;
    line-height: 50px;
    padding-bottom: 15px;
  }
  .common_banner_sec .banner_wrpr_bg {
    background-image: none;
  }

}




/* -----------rd----------------------- */


/* -----------Backend----------------------- */



/*******************************************************************BACKEND CSS****************************************************************************/
/* body{margin:0!important;font-family:"Montserrat",sans-serif} */
code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}
h1,h2,h3,h4,h5,h6,p{padding:0;margin:0}
/* .common_width{width:1610px!important;margin:0 auto} */
a,button{cursor:pointer}
iframe{border:0}
:root{
  --mainbg:#1cb3b0;
  --button-hover-bg:#188b9f;
  --action-icon-color:#047471;
  --button-border-color:#2ad9d3;
  --modal-title-bg:linear-gradient(90deg, rgba(24,139,159,1) 0%, rgba(28,179,176,1) 100%);
  --modal-border:#1cb3b0;
  --modal-close-bg:#1cb3b0;
  --add-button-bg:#43edea;
  --headerprofile-iconbg:#43edea;
  --modal-title-bg2:#d4eaffe3;
  --inactive-btnbg:#66a4e2;
  --backend-body-color:#d1eff5;

--loder-color:#076665;



  
}

.backend_logowrapper {
        background: #fff;
    width: 130px;
    padding: 5px 12px;
}
.backend_logowrapper img{width: 100%;}
@media only screen and (max-width: 1440px) {
.backend_logowrapper {
    width: 102px;
    padding: 10px 12px;
}
}
.backend_navbar{}

.MuiButton-containedPrimary{background-color: var(--mainbg) !important;}
.MuiButton-textPrimary {color: var(--mainbg);}

.profileicon .MuiAvatar-root{background-color: var(--headerprofile-iconbg)}
.upload-container .MuiButtonBase-root{background-color: var(--mainbg)}
.upload-container .MuiButtonBase-root:hover{background-color: var(--button-hover-bg)}

.MuiList-root{max-height: 250px;}
.loginSnack .MuiSnackbarContent-message{width:100%;text-align:center}
/* ------------------------------------------------------ Backend Header ------------------------------------------------------------ */
.backend_navigation_dropdown .MuiButton-endIcon{margin:0}
.backendheader_bar{background-color:var(--mainbg)!important}

/* ---------------------------------------------------------------------------------------------------  List -------------------------------------------------------------*/
.list_wrapper{margin:20px 60px;position:relative!important;border:3px solid var(--mainbg);padding:10px}
.list_header{margin-bottom:20px;display:flex;justify-content:center;background-color:var(--mainbg)!important;color:#fff!important;font-size:32px;padding:5px 0;text-transform:uppercase;align-items:center}
.list_search{padding:10px;margin-bottom:10px;background:linear-gradient(359deg,#feffff,#dae2e9)}
.list_wrapper .list_container img.table_image{height:100px;width:110px;cursor:pointer}
/* --------------------------------------------------------------------- Add Button List ---------------------------------------------------- */
.add_btn{display:flex;flex-direction:row-reverse;margin-bottom:10px}
.add_btn1{margin-right:14px}
.add_btn1 svg{background:var(--add-button-bg);border-radius:50%;cursor:pointer}
/* ------------------------------------------------------------------------------ Add & Search Form -----------------*/



/* ---------------------------------------------------------------- */
 .backend_main_wrapper{background:var(--backend-body-color)}
.backendTableWrapper .backendTableInline{gap:10px;display:flex;flex-wrap:wrap}
.backendTableWrapper_addform .backendTableInline_addform{display:grid;grid-template-columns:1fr 1fr;gap:10px}
.backendTableWrapper_addform .list_search_item div{display:flex;margin:0}
.backendTableWrapper_addform .backendTableInline_addform .submitbuttonwrapper,.deleteBtnWrapper{display:flex;justify-content:center}
.backendTableWrapper .list_search_item div{display:flex;margin:0}
/* .backendTableWrapper .backendTableInline .submitbuttonwrapper{display:flex;justify-content:center} */
.submitbuttonwrapper .MuiButtonBase-root,.deleteBtnWrapper .MuiButtonBase-root{border:none;border-radius:5px;color:#fff;background-color:var(--mainbg);margin-right:8px;font-size:20px;line-height:26px;padding:8px 20px;text-transform:capitalize;box-shadow:2px 3px 8px #225a918f!important;border:1px solid var(--button-border-color);text-transform:capitalize}
.submitbuttonwrapper .MuiButtonBase-root:hover,.deleteBtnWrapper .MuiButtonBase-root:hover{background-color:var(--button-hover-bg)!important}
.backendTableWrapper.ist_search_item .added_by{color:#fff}
.backendTableWrapper_addform{background:#f8f8f8;padding: 3px 3px;}
.submitbuttonwrapper .MuiButtonBase-root:hover{background-color:var(--mainbg);color:#fffb00}
.list_wrapper .MuiTableRow-head{background-color:var(--mainbg)}
.footer_bottom_flex{display:flex;align-items:center}
.backend_footer_bottom{display:flex;justify-content:center!important;align-items:center}
.list_wrapper .MuiTableCell-head span{color:#fff}
.list_wrapper .MuiTableHead-root .MuiTableCell-head{color:#fff;text-align:left}
.list_wrapper .css-1ygcj2i-MuiTableCell-root{color:#fff}
.listCount{text-align:center}
/* .list_wrapper .MuiPaper-root .MuiPaper-elevation{background:linear-gradient(180deg,#feffff,#c9d5df)} */
.list_search_item_added{width:100%}
.list_search_item_added .MuiFormControl-root{width:100%;margin:0!important}
.testwrpr{display:flex!important;flex-wrap:wrap;justify-content:space-between}
.testwrpr .upload_data_wrpr{width:30%}
.testwrpr .submitbuttonwrapper{width:20%!important;flex-direction:row-reverse;justify-content:flex-start}
.testwrpr .upload_data_wrpr #list h1{font-size:15px;background:#dedee6;border-radius:10px;padding:12px;color:var(--mainbg)}
.testwrpr .list_search_item_added:nth-child(odd) .MuiOutlinedInput-input{background:#ebebeb}
.testwrpr .list_search_item_added:nth-child(even) .MuiOutlinedInput-input{background:#ebebeb}
.test_one{display:flex!important;flex-wrap:wrap}
.test_one .list_search_item{width:32%}
.Deletemodal .MuiBox-root{}
.Deletemodal h2{font-size:24px;font-weight:400;margin:0;padding:0 0 10px}
.Deletemodal button:hover{background-color:var(--mainbg);color:#fffb00}
.modalCloseBtn{position:absolute!important;right:-20px;top:-20px;background-color:#fff!important;padding:0!important;color:#000!important;margin:0!important;background:#fff!important}
.modalCloseBtn svg{width:28px;height:28px}
.Deletemodal h2{font-size:18px;font-weight:510;margin:0 0 14}
.custommodal h1{color:#fff;font-size:30px;margin:0;padding:7px 0 8px;text-align:center;font-weight:var(--main-medium);background:linear-gradient(274deg,#226bbc 0,#0328fb 100%)}
.custommodal{width:100%;display:flex;align-items:center;justify-content:center;height:100vh}
.custommodal .MuiBox-root{background:#c9d5df;background:linear-gradient(180deg,#dfe8ed 0,#c9d5df 100%);padding:10px;border-radius:15px;border:solid 5px #fff;min-width:640px;max-width:90%;position:relative;top:0!important;left:0!important;text-align:left!important;transform:inherit!important;max-height:68%;display:flex;flex-direction:column;max-height:100%;position:relative}
.CloseModal{justify-content:flex-end!important;position:absolute!important;right:-25px;top:-25px}
.CloseModal svg{width:28px;height:28px;background-color:#fff;border-radius:50%}
.modalview_group{padding:5px;border:solid 1px #b8c4d3;overflow:auto;height:550px;block-size:auto;max-height:550px}
.modalview_group div{display:flex;flex-direction:row!important}
.modalview_group div label:nth-child(odd){width:50%!important;font-weight:500}
.modalview_group div label:nth-child(even){width:93%!important;padding:10px}
.modalview_group label{font-size:20px}
.modalview_group label:first-of-type{color:#0a0a0a;padding:10px}
.modalview_group:nth-of-type(odd){background-color:#b8c4d3}
.modalview_group:nth-of-type(even){background:linear-gradient(355deg,#fff,#7ba8ce)}
.TesTimonialTable .MuiTableContainer-root .title{width:16%!important}
.noDataAvailable{text-align:center;font-size:26px;font-weight:400;color:#e11818;padding:20px}
.heightTableHader .MuiToolbar-root{display:none}
.modalBaseStyle{overflow-y:auto}
.modalBaseStyle .MuiDialog-container .MuiPaper-root{margin: 0; box-shadow: none;background-color: transparent;}
.modalBaseBox{background-color:#fff;padding:20px;border:5px solid var(--modal-border);border-radius:5px;margin:40px auto;position:relative;width:fit-content;outline:none}
.largeModal{max-width:80%;min-width:500px}
.mediumModal{max-width:50%;min-width:500px}
.smallModal{max-width:30%;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);min-width:500px}
.DeleteModalTitle{text-align:center;margin-bottom:20px}
.previewModalTitle{background:var(--modal-title-bg);color:#fff;font-size:30px;font-weight:var(--main-medium);margin:0;padding:7px 0 8px;text-align:center}
.modalImageWrapper img{}
.modalImageWrapper{display:block;box-shadow:none!important;border:1px solid #bdb4b4;padding:5px;margin-bottom:15px}
.modalTopTitle {
background: var(--modal-title-bg);
  font-size: 28px;
  padding: 10px;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
  font-weight: 600;
}
.modalAlertTitle ,.Deletemodal .modalAlertTitle  {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  color: #054443;
  padding: 0;
}
/* ------------------ACTIVE AND INACTIVE STASTUS CSS---------- */
.acblockdetailswrp .MuiDialog-paperWidthSm {
  min-width: 576px;
  max-width: calc(100vw - 384px);
  padding: 20px;
  position: relative;
  background: #c9d5df;
  background: linear-gradient(355deg, #fff, #7ba8ce);
  padding: 10px;
  border-radius: 15px;
  border: solid 5px #fff;
  width: auto;
  min-width: 600px;
  max-width: 90%;
  position: relative;
  top: 0 !important;
  left: 0 !important;
  text-align: left !important;
  transform: inherit !important;
  max-height: 68%;
  display: flex;
  flex-direction: column;
}

.acblkclosebtn {
  position: absolute;
  right: -10px;
  top: -10px;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
}

.acblockdetailswrp .MuiDialog-paper h2 {
  margin: 0;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #fff;
  font-size: 25px;
  line-height: normal;
  background: linear-gradient(274deg, #226bbc 0, #0328fb 100%);
}

.acblockdetailswrp .MuiDialog-paper .btnstatuswrapper {
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 10px;
}

.acblockdetailswrp .MuiDialog-paper .btnstatuswrapper .blockbtncls {
  border: none;
  border-radius: 12px;
  color: #fff;
  background-color: var(--mainbg);
  margin-right: 8px;
  font-size: 20px;
  padding: 3px 26px;
  margin-top: -9px;
}

.acblockdetailswrp .MuiDialog-paper .btnstatuswrapper .blockbtncls:hover {
  background-color: var(--mainbg);
  color: #fffb00;
}

.layoutTopProgressBar {
  position: absolute !important;
  right: -5px;
  top: -5px;
}

/* --------------------------------------------------------------------------- */

/* --------------------------consolated form css------------------------------------ */
.countDiv {
  height: auto;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: center;
  background: transparent;
  box-shadow: none;
}

.countDiv__column4,
.countDiv__column3,
.countDiv__column2,
.countDiv__column1,
.countDiv__column {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  box-shadow: none;
}

.countDiv__column--col1 {
  flex: 1 0 6.33333333% !important;
}

.countDiv__column--col2 {
  flex: 1 0 14.66666667% !important;
}

.countDiv__column--col2sp {
  flex: 1 0 19.66666667% !important;
}

.countDiv__column--col3 {
  flex: 1 0 23% !important;
}

.countDiv__column--col4 {
  flex: 1 0 31.33333333% !important;
}

.countDiv__column--col5 {
  flex: 1 0 39.66666667% !important;
}

.countDiv__column--col6 {
  flex: 1 0 48% !important;
}

.countDiv__column--col7 {
  flex: 1 0 56.33333333% !important;
}

.countDiv__column--col8 {
  flex: 1 0 64.66666667% !important;
}

.countDiv__column--col9 {
  flex: 1 0 73% !important;
}

.countDiv__column--col10 {
  flex: 1 0 81.33333333% !important;
}

.countDiv__column--col11 {
  flex: 1 0 89.66666667% !important;
}

.countDiv__column--col12 {
  flex: 1 0 98% !important;
}

.list_wrapper .css-1ygcj2i-MuiTableCell-root {
  color: #fff;
}

.stackPagination .listCount {
  display: flex !important;
  justify-content: center !important;
}

.list_wrapper .team_management_list_container .MuiPaper-elevation {
  /* background: linear-gradient(180deg, #feffff, #ddebf7); */
}

/* ------------------------------------------------------------------------------------------------------------------------- */
.tblform .inlnfrm {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  flex-direction: column;
}

.tblform .list_search_item div {
  display: flex;
  margin: 0;
}

.tblform .inlnfrm .submitbuttonwrapper {
  grid-column: 1/-1;
  display: flex;
  justify-content: center;
}

.inlnfrm .fieldwidth {
  margin-bottom: 17px;
}

.inlnfrm .fieldwidth .MuiInputBase-root {
  border-radius: 0;
}

.inlnfrm .fieldwidth fieldset {
  border-bottom: 2px solid #d5e4ea !important;
  border-width: 0 0 2px 0 !important;
}

.inlnfrm .fieldwidth .MuiFormControl-root {
  width: 100%;
}

.inlnfrm .fieldwidth label {
  left: -15px;
  font-size: 20px;
  color: #000;
}

.inlnfrm .howcanwehelp label {
  left: 0;
}

.inlnfrm .year .MuiFormControl-root {
  margin: 0px;
}

.inlnfrm .fieldwidth textarea {
  width: 100%;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid #d5e4ea;
  height: 60px !important;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid #d5e4ea;
  resize: none;
  background: transparent;
  color: rgb(1, 5, 5);
  font-size: 20px;
  font-family: "Roboto";
}



/* .MuiGrid-root.MuiGrid-container.contact_sub_wrpr.css-11lq3yg-MuiGrid-root {
  align-items: center;
  border-radius: 10px;
  box-shadow: 1px 3px 80px rgb(166 179 187 / 61%);
  padding: 30px;
} */
.tblform .inlnfrm {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  flex-direction: column;
}

.tblform .list_search_item div {
  display: flex;
  margin: 0;
}

.tblform .inlnfrm .submitbuttonwrapper {
  grid-column: 1/-1;
  display: flex;
  justify-content: center;
}

.inlnfrm .fieldwidth {
  margin-bottom: 17px;
}

.inlnfrm .fieldwidth .MuiInputBase-root {
  border-radius: 0;
}

.inlnfrm .fieldwidth fieldset {
  border-bottom: 2px solid #d5e4ea !important;
  border-width: 0 0 2px !important;
}

.inlnfrm .fieldwidth .MuiFormControl-root {
  width: 100%;
}

.inlnfrm .fieldwidth label {
  left: -15px;
  font-size: 20px;
  color: #02384a;
}

.inlnfrm .howcanwehelp label {
  left: 0;
}

.inlnfrm .year .MuiFormControl-root {
  margin: 0;
}

.inlnfrm .fieldwidth textarea {
  width: 100%;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid #d5e4ea;
  height: 60px !important;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid #d5e4ea;
  resize: none;
  background: transparent;
}



/* --------------------------Dashboard css------------------------------------------------------------------ */
/* .dashboard_wrapper {
  margin: 20px;
} */
.card_block {
  display: block;
  flex: 1 0 32%;
  height: auto;
  margin: 10px;
  background: #fff;
  -webkit-box-shadow: 0 0 5px 1px rgb(0 0 0 / 41%);
  position: relative;
  padding: 15px;
}

/* --------------------------------------Video Gallery-------------------- */

/*-------------------------------------------doctor card section---------------------------------*/
.card_container .card_details_item {
  display: flex;
  margin-bottom: 8px;

  align-items: center;
}
.card_container .card_details_item svg {
  color: #525351;
}
.card_details_item .card_font {
  margin: 0 5px;
  font-weight: 600;
  color: #525351;
}

.card_container .card_details_name {
  margin-bottom: 13px;
  font-size: 21px;
}

.card_container:hover {
  background: var(--mainbg);
  color: #fcf5e5;
}

.closeicon {
  padding-left: 94%;
}

.dialoug {
  border-radius: 10px;
}

.morebutton {
  color: #fff;
  background-color: #4caf50;
}

.view_all_button {
  margin: auto;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

.view_all {
  background-color: #89898961;
  height: 29px;
  width: 85px;
  font-size: 16px;
}

.show {
  margin-bottom: 5px;
}

/*----------------------------------------------------*/
/*----------------------Image card section------------------------------*/
.card_image_1 {
  width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.5s ease 0;
  -moz-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
}

.card_image_1:hover {
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  transform: scale(1.2);
  opacity: 1;
}

.image_card_title {
  display: block;
  margin: 0 0 15px;
  text-transform: uppercase;
  font-size: 24px;
  color: #221c17;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 2%;
}

.image_card_description {
  width: 100%;
  font-size: 16px;
  color: #707172;
  text-align: left;
  margin: 0;
  padding: 0;
  float: none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.image_card_description p,
.image_card_description span,
.image_card_description ul,
.image_card_description ol,
.image_card_description a,
.image_card_description h1,
.image_card_description h2,
.image_card_description h3,
.image_card_description h4,
.image_card_description h5,
.image_card_description h6 {
  font-size: 16px;
  font-weight: 500;
}

.pointer {
  cursor: pointer;
}

.modal_icon_button {
  position: absolute !important;
  right: -14px;
  top: -17px;
  height: 30px;
  width: 30px;
  background-color: var(--modal-close-bg) !important;
  opacity: 1;
  padding: 5px !important;
  border-radius: 50% !important;border: 0;
}

.modal_icon_button svg {
  fill: rgb(255, 255, 255);
}
.modal_icon_button .material-icons{
  font-size: 21px !important;
  color: #fff;
}

.share_icon.MuiIcon-root {
  width: 36px;
  height: 36px;
}

.read_more {
  margin-left: 76%;
}

.read_more:hover {
  text-decoration: underline;
}

.card_css {
  border: 1px solid #343434;
  /* box-shadow:0 6px 6px -3px rgb(0 0 0 / 5%),0 10px 14px 1px rgb(0 0 0 / 5%),0 4px 18px 3px rgb(0 0 0 / 5%)!important; */
  box-shadow: none !important;
  border-radius: 5px !important;
}

.title_css,
.subTitle {
  margin-bottom: 15px !important;
  margin-top: 0px !important;
}
.mb0 {
  margin-bottom: 0px !important;
}

.videoModalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 5px solid var(--modal-border);
  border-radius: 5px;
  width: 800px;
}

.speed_class .css-118zhtq-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
  width: 40px;
  height: 40px;
}

.play_button_icon {
  position: sticky;
  top: 32%;
  left: 37%;
  transform: translate(-22%, -29%);
  width: 43px;
  height: 46px;
}

.iconclass {
  margin-top: 7px;
}

.img_title {
  color: rgb(0, 0, 0);
  font-size: 20px;
  margin: 5px 0px 15px;
  padding: 8px 8px 8px;
  text-align: center;
  font-weight: var(--main-medium);
  background: linear-gradient(90deg, rgba(24,139,159,1) 0%, rgba(28,179,176,1) 100%);
  border-radius: 5px;
  border: 1px solid #000;
  font-weight: 500;
}

.imageTable td button {
  color: var(--action-icon-color);
}

.videoTable td button {
  color: var(--action-icon-color);
}

.bannerTable td button {
  color: var(--action-icon-color);
}

.noteslist td button {
  color: var(--action-icon-color);
}

.socialMediaAdvoTable td button {
  color: var(--action-icon-color);
}

.loader_class {
  margin-bottom: 3px;
}

.card_padding {
  margin-top: 20px;
}

.card_padding .card_container .card_image {
  width: 35%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 212px;
}
.card_padding .card_container .card_image lazy-img {
  height: 100%;
}

.card_padding .card_container .card_image img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: center center;
  border-radius: 0;
}

.card_padding .card_container .card_details {
  width: 63%;
}

.card_padding .img_card_media lazy-img {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.card_padding .img_card_media lazy-img .lazy_img_card {
  height: 100%;
}

.cardsearch {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  background-color: var(--mainbg) !important;
  color: #fff !important;
  font-size: 25px;
  padding: 0px 0;
  margin-top: -4px;
}

.cardsearchheadind {
  text-transform: uppercase;
  font-weight: 400;
}

.cardsearchBarCloseIcon {
  position: absolute;
  top: 8px;
  right: 12px;
  background: #fff;
  border-radius: 22px;
}

.dashboard_wrapper .filtersearch {
  position: absolute;
  top: 7%;
  right: 2%;
  color: #fff;
}

.dashboard_wrapper .reloadsearch {
  position: absolute;
  top: 7%;
  right: 0%;
  color: #fff;
}

.read_more_icon_class {
  margin-left: 63%;
}
.read_more_icon_video_class {
  margin-left: 71%;
}

.img_cardwrpr .card_details {
  padding: 0;
}
@media only screen and (max-width: 1600px) {
.card_padding .card_container .card_details_name{font-size:20px}
.card_padding .card_container .card_details span{font-size:14px}
}
@media only screen and (max-width: 1318px) {
.card_padding .dctor_crdMainwrpr .dctor_crdwrpr{flex-basis:100%;max-width:100%}
.card_padding .card_container .card_image{width:25%;height:100%}
}
@media only screen and (max-width: 900px) {
.card_padding .card_container .card_image{height:200px}
}
@media only screen and (max-width: 600px) {
.card_padding .dctor_crdMainwrpr .card_container{flex-direction:column}
.card_padding .card_container .card_image{width:100%;height:auto}
.card_padding .card_container .card_details{width:94%;padding:15px}
.card_padding .card_details_taxonamy .fontsize_doc{padding-right:10px}
.img_modalwrp{width:75%!important}
}
/* --------------------------------------------------------------------- video card css ---------------------------------------------------- */
.uploadedvid_wrpr .uploadedvid_subwrpr {
  padding: 14px;
}

.uploadedvid_wrpr .uploadedvid_subwrpr .card_details {
  padding: 0;
}

.uploadedvid_wrpr .share_portion_video_card {
  margin-top: 3%;
  /* margin-bottom: 2%; */
}

.player-wrapper {
  height: 344px;
  display: block;
  border: 1px solid #bdb4b4;
  padding: 5px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 600px) {
  .player-wrapper{height:244px}
}

.demoimage {
  display: block;
  max-width: 100%;
}

.demoimagebanner {
  margin-top: -8%;
}

.cc_demoimage2 {
  margin-top: -3%;
}

.cc_demoimage3 {
  margin-top: -10%;
}


.listCount {
  text-align: center;
}



/************************************************ Add User*********************************************/
.adduser_wrapper {
  margin: 20px 100px;
  position: relative !important;
  border: 3px solid var(--mainbg);
  padding: 10px;
}

.adduser_header {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  background-color: var(--mainbg) !important;
  color: #fff !important;
  font-size: 32px;
  padding: 5px 0;
  text-transform: uppercase;
}

.addadminwrpr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 3px inset #2470ba;
  padding: 15px;
}

.inputs1 {
  width: 48%;
  margin-top: 20px;
}

.inputs2 {
  width: 48%;
  margin-top: 20px;
}

.inputs1 .MuiFormControl-root {
  width: 100%;
}

.inputs2 .MuiFormControl-root {
  width: 100%;
}

.upload_data_wrpr {
  width: 100%;
  background: #fff;
  border: 2px dashed #1cb3b0;
}

.blog_img_wrpr {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
}

.addadminwrpr .upload_data_wrpr .blog_img_wrpr #list h1 {
  width: 100%;
  display: block;
  font-size: 15px;
}

.addadminwrpr .Description {
  margin-bottom: 20px;
}

.addadminwrpr .submitbuttonwrapper {
  margin: 20px 0 0;
  display: flex;
  flex-direction: row-reverse;
}

/* **************************************************** Add User Drawer*******************************************************/
.MuiModal-root-MuiDrawer-root {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.drawer_wrpr h2 {
  width: 100%;
  margin: 0 0 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  padding: 5px;
  line-height: 38px;
  background: linear-gradient(90deg, rgba(24,139,159,1) 0%, rgba(28,179,176,1) 100%);
  color: #fff;
  font-weight: 500;
}

.add_users_drawer .MuiPaper-root {
  max-width: 820px !important;
  display: block;
  margin: 0 auto;
  overflow: hidden;
}

/* .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
} */

.add_user_button_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 5px 10px;
}

.drawer_wrpr {
  background: linear-gradient(359deg, #feffff, #c3d6e6bf);
}

.add_user_button_wrapper button {
  width: 31%;
  /* flex: 1 0 31%; */
  margin: 0.5% 1%;
}

/*-----blog preview-------*/
.modalview_group img {
  width: 150px;
}

.GoToHome.GoToHomelogin {
  top: 116px !important;
  position: fixed !important;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8) !important;
  border-radius: 25px 0 0 25px;
  padding: 5px;
  z-index: 9;
  border: 1px solid #80808087;
  min-width: 54px !important;
}

.GoToHome.GoToHomelogin a {
  display: flex !important;
  color: #26688b;
}

.GoToHome.GoToHomelogin svg {
  color: var(--mainbg);
}

.GoToHomelogin .MuiSvgIcon-root {
  width: 36px;
  height: 36px;
}

.GoToFrontend {
  top: 116px !important;
  position: fixed !important;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8) !important;
  border-radius: 25px 0 0 25px !important;
  padding: 5px !important;
  z-index: 9;
  border: 1px solid #80808087 !important;
  min-width: 54px !important;
}

.GoToFrontend svg {
  width: 36px;
  height: 36px;
  color: var(--mainbg);
}

/* ==================testimonial-carousel adjustment STARTS====================== */
.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
  top: 48%;
  transform: translateY(-48%);
  min-width: 35px !important;
  min-height: 35px !important;
  background: #c1c1c173 !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
  top: 48%;
  transform: translateY(-48%);
  min-width: 35px !important;
  min-height: 35px !important;
  background: #c1c1c173 !important;
}

.react-multiple-carousel__arrow:hover {
  background: #dae8ad !important;
}

.react-multiple-carousel__arrow::before {
  color: #0b5fa5 !important;
}

@media only screen and (max-width: 1199px) {
  .react-multiple-carousel__arrow--right {
    top: 60%;
  }

  .react-multiple-carousel__arrow--left {
    top: 60%;
  }
}

@media only screen and (max-width: 991px) {
  .react-multiple-carousel__arrow--right {
    top: 65%;
    min-width: 30px !important;
    min-height: 30px !important;
  }

  .react-multiple-carousel__arrow--left {
    top: 65%;
    min-width: 30px !important;
    min-height: 30px !important;
  }

  .react-multiple-carousel__arrow--right {
    top: 51%;
  }

  .react-multiple-carousel__arrow--left {
    top: 51%;
  }
}

/* ==================testimonial-carousel adjustment ENDS====================== */

/*-----------------------------------Team Management Listing Icons---------------------*/

.userTable td button {
  color: #2077cf;
}

.recordgif {
  width: 100%;
}

.uploadermodal h2 {
  font-size: 24px;
  font-weight: 510;
  margin: 0;
  padding: 0 0 10px;
  text-align: center;
}

.buttonWrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.player {
  width: 100%;
  margin-bottom: 0px;
}

.recordcontentwrapprer {
  margin: 15px 0;
}

.resetbuttonwrapper {
  text-align: center;
}

.resetbuttonwrapper button {
  cursor: pointer;
}

.ordiv {
  width: 100%;
  text-align: center;
}

.auidovideomodal .upload_data_wrpr {
  width: inherit !important;
  padding: inherit !important;
  background: inherit !important;
  border: inherit !important;
}

.auidovideomodal .submitbuttonwrapperrr {
  text-align: center;
}

.team_management_list_header {
  position: relative;
}

.team_management_list_header .addteam {
  position: absolute;
  right: 0;
  top: 3px;
  box-shadow: none;
}

.team_management_list_header .addteam1 {
  position: absolute;
  right: 0;
  top: 12px;
  box-shadow: none;
}

.team_management_list_header .addteam button {
  background: #3f9dff;
  box-shadow: none;
  right: 6px;
}

.team_management_list_header p {
  line-height: 1.6;
  flex: 1 1 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-left: 70px;
}

.team_management_list_header .filtersearch,
.team_management_list_header .reloadsearch {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgb(255 255 255);
}

.srchfld_wrp {
  display: flex;
}

@media only screen and (max-width: 991px) {
  .team_management_list_header p {
    font-size: 26px;
  }
}

@media only screen and (max-width: 700px) {
  .team_management_list_header {
    flex-direction: column;
  }

  .team_management_list_header p {
    padding-left: 0;
    font-size: 17px;
    text-align: center;
    line-height: 24px;
  }

  .srchfld_wrp {
    justify-content: center;
  }
}

/************************Testimonial****************/
/* .userTable button svg {display:none;} */
/* .TesTimonialTable tr th:nth-child(11) svg{color:#fff}
 .submitbuttonwrapper .submit span:first-child{
  display: none !important;
 }
 .submitbuttonwrapper .submit span .notranslate{display: block !important;} */
.team_management_list_wrapper td.youtyub_video_thumbnail {
  position: relative;
  width: 108px;
}

.add_audio-video-hint {
  font-size: 15px;
  margin-top: 6px;
}

.team_management_list_wrapper
  td.youtyub_video_thumbnail
  .table_image_warpper::after {
  width: 30px;
  height: 30px;

  content: "\25BA";
  top: 50%;

  left: 50%;
  position: absolute;
  display: inline-block;
  border: solid 1px #fff;
  border-radius: 100px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%);
  font-size: 26px;
  cursor: pointer;
}

/************************Priority Cursor css*****************/
.priority {
  cursor: pointer;
}

.team_management_list_wrapper td.video_thumbnail {
  position: relative;
  width: 108px;
}

.team_management_list_wrapper td.video_thumbnail .table_image_warpper::after {
  width: 30px;
  height: 30px;

  content: "\25BA";
  top: 50%;

  left: 50%;
  position: absolute;
  display: inline-block;
  border: solid 1px #fff;
  border-radius: 100px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%);
  font-size: 26px;
  cursor: pointer;
}

.se-image-container figure {
  width: 100px;
  height: 100px;
}
.footer_top_section .se-image-container figure {
  width: auto;
  height: auto;
}

.header_section_bottom .se-image-container figure {
  width: auto;
  height: auto;
}

.se-image-container figure img {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

/***Team Management Search*******/

.searchteamamangement {
  display: flex;
  justify-content: center;
}

.addform {
  right: -26px;
  top: -28px;
}

.edt_dtls {
  right: -16px;
  top: -18px;
}

.selection_box_div {
  width: 847px;
}

.login_main_wrpper .MuiOutlinedInput-input:-webkit-autofill {
  border-radius: 40px 0 0 40px !important;
  background: #000 !important;
}

.contact_us .MuiInputBase-input-MuiOutlinedInput-input {
  padding-left: 0 !important;
  color: #277;
}

/**************************************************For calender create event *****************************************************************************/
.App{text-align:center}
.App-logo{height:40vmin;pointer-events:none}
@media (prefers-reduced-motion: no-preference) {
.App-logo{animation:App-logo-spin infinite 20s linear}
}
.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:#fff}
.App-link{color:#61dafb}
@keyframes App-logo-spin {
from{transform:rotate(0deg)}
to{transform:rotate(360deg)}
}

/******************************************************************************************************************************************************/

/***********Priority Modal****************/

.set_priority_value {
  text-align: left;
  margin-left: -12px;
}

.set_priority_value .MuiButtonBase-root {
  padding: 1px 10px;
}

.priority_set {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.priority_set h6 {
  margin-top: 7px;
  margin-right: 5px;
  font-size: 17px;
  font-weight: 600;
}

.priority_field_value .MuiOutlinedInput-input {
  padding: 2px 0 14px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.priority_field_value .MuiOutlinedInput-input {
  width: 70%;
}

.priority_field_value .MuiOutlinedInput-notchedOutline {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: unset;
  height: 30px;
  border-bottom: 2px solid black;
  width: 60%;
}

.priority_field_value .MuiFormHelperText-root {
  margin-top: -6px;
  margin-left: 2px;
}

/*********** Css for ADD Notes***************/
.MuiDialog-paperWidthSm {
  max-width: inherit !important;
  overflow-y: visible !important;
}

.adddnote {
  background: linear-gradient(90deg, var(--mainbg) 100%, #fff2d0 0);
  font-size: 25px;
  max-height: 70px;
  text-align: center;
  color: #fff;
}

.notesforusername {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.notescontainer {
  background: linear-gradient(139deg, #dfe8ed, #c9d5df);
}

.noteslist .MuiToolbar-gutters {
  background-color: #fff;
}

.noteslist .MuiToolbar-gutters .MuiTypography-h6 {
  color: rgb(10, 10, 10);
}

.notescontainer .blockInnerWrapper {
  background: #c9d5df !important;
  margin-top: 25px;
}

.noteslist
  .tablePagination
  .MuiToolbar-gutters
  .MuiTablePagination-selectLabel {
  color: rgb(12, 12, 12);
}

.noteslist .tablePagination .MuiToolbar-gutters .MuiInputBase-colorPrimary {
  color: rgb(16, 16, 16);
}

.noteslist .tablePagination .MuiToolbar-gutters .MuiSvgIcon-fontSizeMedium {
  color: rgb(18, 17, 17);
}

.noteslist
  .tablePagination
  .MuiToolbar-gutters
  .MuiTablePagination-displayedRows {
  color: rgb(13, 13, 13);
}

/* ===============================backend css========================== */
@media (max-width: 1530px) {
  .headercontainer {
    padding: 0 !important;
  }
}

#addaudiovideofrommodal .box {
  width: 100%;
  margin: 20px;
}

#addaudiovideofrommodal .box .mp4 {
  margin: 0 auto;
}

.image_name {
  display: block;
  background: #e95e14;
  padding: 10px;
  margin-top: 10px;
  color: #fff;
  /* white-space: pre-wrap; */
  word-break: break-all;
}

.btn_container .removeImage {
  background-color: var(--mainbg) !important;
  margin: 5px;
}

.btn_container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
  align-items: center;

  border-radius: 15px;
  flex: 1 0 auto;
  padding: 20px;
  margin-bottom: 15px;
}

.formInline .textareaaa {
  width: 100%;
}

.formInline .textareaaa textarea {
  background-color: #f0f0f0 !important;
  border: 1px solid #9a9a9a;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  flex: 1 1;
  font-size: 20px;
  height: 50px !important;
  margin-bottom: 0;
  margin-top: 10px;
  overflow: hidden;
  height: 100px !important;
  width: 786px;
}

.formInline .textareaaa .MuiFormControl-root {
  max-width: 100%;
  width: 100%;
}

.formInline .submitbuttonwrapper {
  justify-content: center;
  display: flex;
  margin: 0 auto;
  width: 100%;
  padding: 15px 0;
}

.notesusername {
  color: var(--mainbg);
}

#AddnotesForm .formInline .submitbuttonwrapper {
  justify-content: center;
}

/**********************Ad users***********************/

.addAdmin .submitbuttons {
  width: auto !important;
}

.radio .MuiFormGroup-root {
  flex-direction: row;
}

.radio .MuiFormLabel-colorPrimary {
  font-size: 20px;
}

.vdo1 {
  background-image: url("../src/assets/images/play-icon-audio-or-video-player-sign-vector-10149615.jpg");
  background-size: contain;
  width: 70px;
  height: 70px;
  display: block;
  cursor: pointer;
  background-color: #85b1df;
  border-radius: 50px;
}

.youtube1 {
  background-image: url("../src/assets/images/play-icon-audio-or-video-player-sign-vector-10149615.jpg");
  background-size: contain;
  width: 70px;
  height: 70px;
  display: block;
  cursor: pointer;
  background-color: #85b1df;
  border-radius: 50px;
}

.audio1 {
  background-image: url("https://all-frontend-assets.s3.amazonaws.com/galveston-physical-medicine/assets/images/Circle-icons-mic.svg.png");
  background-size: contain;
  width: 70px;
  height: 70px;
  display: block;
  cursor: pointer;
  background-color: #85b1df;
  border-radius: 50px;
}

.audiomodal .react-audio-player {
  width: 100%;
  margin-top: 10px;
  border: 1px solid #000;
  border-radius: 50px;
}

.modl2525 {
  width: 25% !important;
}

.modalimg1 {
  padding: 15px !important;
}

.modalimg1 .img_title {
  margin: 15px 0px 15px;
}

.modalimg1 .card_css img {
  /* object-fit: fill !important; */
  height: auto;
}

.modalview_group div:nth-child(odd) {
  background-color: #f6f6f6d9;
}
.inactivebtncls {
  background-color: var(--inactive-btnbg) !important;
}

/* =================================backend Table listing refresh Icon css========================== */
/* .TesTimonialTable .MuiToolbar-root .MuiButtonBase-root .MuiSvgIcon-root {
  display: none;
} */
.TesTimonialTable .MuiToolbar-root .replayicon,
.videoTable .MuiToolbar-root .replayicon,
.imageTable .MuiToolbar-root .replayicon,
.bannerTable .MuiToolbar-root .replayicon,
.blogTable .MuiToolbar-root .replayicon,
.userTable .MuiToolbar-root .replayicon,
.socialMediaAdvoTable .MuiToolbar-root .replayicon,
.locationTable .MuiToolbar-root .replayicon {
  display: none;
}

/*=================================testimonial form hint css ================================*/
.imagehint {
  position: absolute;
  top: 58%;
  right: 3%;
}

.videohint {
  position: absolute;
  top: 69%;
  right: 52%;
}

.audiohint {
  position: absolute;
  top: 69%;
  right: 3%;
}

/* =================================responsive backend css========================== */
@media (max-width: 1400px) {
  .list_wrapper .TesTimonialTable tr th {
    padding: 0px;
    text-align: center;
  }
}

@media (max-width: 1330px) {
  .list_wrapper .TesTimonialTable tr th {
    padding: 0px;
    text-align: center;
  }
}

@media screen and (max-width: 1199px) {
  .countDiv__column--col4 {
    flex: 1 0 49% !important;
  }
}

@media screen and (max-width: 760px) {
  .countDiv__column--col4 {
    flex: 1 0 100% !important;
  }
}

/* -----------********patinent search css********------------- */
.PatientSearch {
  background: linear-gradient(359deg, #feffff, #dae2e9);
}

.PatientSearch .searchBarHead h2 {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  background-color: var(--mainbg) !important;
  color: #fff !important;
  font-size: 30px;
  padding: 5px 0;
  text-transform: uppercase;
  font-weight: 500;
}

.PatientSearch .searchForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.PatientSearch {
  position: relative;
  padding: 0 10px 0px 10px;
}
.searchBarHead {
  margin-left: -10px;
  margin-right: -10px;
}

.PatientSearch .searchBarCloseIcon {
  position: absolute;
  top: 7px;
  right: 5px;
}

.PatientSearch .searchBarCloseIcon .MuiButtonBase-root {
  background: #fff;
}

.PatientSearch .searchForm .countDiv__column--col3 {
  margin: 5px;
}

.PatientSearch .searchForm .countDiv__column--col3:nth-child(2) {
  flex-basis: 20% !important;
  max-width: 20% !important;
  width: 20%;
}

.PatientSearch .searchForm .countDiv__column--col3:nth-child(3) {
  flex-basis: 25% !important;
  max-width: 25% !important;
  width: 25%;
}

.PatientSearch .searchForm .countDiv__column--col3:nth-child(3) {
  flex-basis: 25% !important;
  max-width: 25% !important;
  width: 25%;
}

.PatientSearch
  .searchForm
  .countDiv__column--col3:nth-child(3)
  .MuiOutlinedInput-root,
.PatientSearch
  .searchForm
  .countDiv__column--col3:nth-child(4)
  .MuiOutlinedInput-root {
  padding-top: 12px;
  padding-bottom: 12px;
}

.searchForm .MuiButton-root,
.blue_btn button {
  border: none;
  border-radius: 9px;
  color: #fff;
  background-color: var(--mainbg) !important;
  margin-right: 8px;
  font-size: 20px;
  padding: 10px 16px;
  cursor: pointer;
}
.forgot_pass_wrapper {
  width: 100%;
}

.user_update p {
  font-size: 20px;
  margin-top: 10px;
  font-weight: bold;
  line-height: 27px;
}

.user_update p span {
  font-weight: 400;
}

.profile .txt_shr_bttn h2 {
  font-size: 30px;
}

.user_update {
  column-count: 1;
  column-gap: 30px;
}

.profile .MuiGrid-root {
  padding-top: 0 !important;
}

.profile {
  margin-top: 0px;
}

.req_tstmnl .countDiv__column--col6 {
  flex: 1 0 47% !important;
  margin: 0.5%;
}

.backend_main_wrapper .view_all_button button {
  display: block;
  margin: 0 auto;
  text-transform: capitalize;
  background-color: var(--mainbg);
}

.backend_main_wrapper .passrst_myacnt .formInline {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.passrst_myacnt .formInline .countDiv__column--col4 {
  margin: 10px;
}

.passrst_myacnt .formInline .countDiv__column--col6 {
  margin: 5px;
}

.passrst_myacnt .password .MuiFormControl-root {
  width: 100%;
}

.passrst_myacnt .myaccnt .myaccntwpr {
  display: flex;
  justify-content: space-between;
}

.passrst_myacnt .myaccnt .myaccntwpr .myacntimg {
  background: var(--mainbg)66;
  border-radius: 5px;
  flex-basis: 20%;
  max-width: 20%;
}

.passrst_myacnt .myaccnt .myaccntwpr .myacntimg .img_card_media {
  overflow: hidden;
  padding: 5px;
  height: 97.5%;
  width: auto;
}

.passrst_myacnt .myaccnt .myaccntwpr .myacntimg .img_card_media img {
  width: 100%;
  display: block;
  object-fit: cover;
  object-position: center;
  height: 100%;
}

.passrst_myacnt .myaccnt .myaccntwpr .txt_shr_bttn {
  width: 78%;
  padding-left: 20px;
}

.passrst_myacnt .view_all_button {
  display: flex;
  justify-content: start;
  margin: 10px 0 auto 0;
}

.passrst_myacnt .view_all_button button {
  margin: 0 0 auto 0;
}

.uploadedimgs_wrpr {
  display: flex;
  justify-content: space-between;
}

.uploadedimgs_wrpr svg {
  margin-left: 0;
}

.card_padding .card_details_taxonamy .fontsize_doc,
.card_padding .card_details_email .fontsize_doc {
  word-break: break-all;
}
span.morebtn {
  color: rgb(4 6 5);
  cursor: pointer;
}
.passrst_myacnt .myaccnt .myaccntwpr .user_update p:first-child {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 15px;
}

.frstblck_hdr p {
  padding-left: 0;
}

.passrst_myacnt .formInline .countDiv__column--col6 textarea {
  background: #dee5eb;
}

@media screen and (max-width: 1199px) {
.passrst_myacnt .myaccnt .myaccntwpr .myacntimg{flex-basis:50%;max-width:50%;display:block;margin:0 auto}
.passrst_myacnt .myaccnt .myaccntwpr .txt_shr_bttn{width:100%;padding-left:0;margin-top:10px}
.passrst_myacnt .myaccnt .myaccntwpr .myacntimg .img_card_media{height:auto}
.passrst_myacnt .myaccnt{margin-top:10px}
.wl_img_holder{width:94%;margin:0 auto}
}
@media screen and (max-width: 991px) {
.passrst_myacnt .submitbuttonwrapper .MuiButtonBase-root{font-size:16px;padding:10px}
.list_wrapper{margin:20px}
.passrst_myacnt .upload-container .box{width:50%}
.passrst_myacnt .upload-container .image_container{margin-bottom:0}
.passrst_myacnt .upload-container .btn_container{padding:10px 0;margin-bottom:0}
}
@media screen and (max-width: 650px) {
.passrst_myacnt .formInline .submitbuttonwrapper button{width:48%;margin:.5%;font-size:14px;padding:5px;line-height:20px}
.passrst_myacnt .formInline .submitbuttonwrapper{width:100%;flex-wrap:wrap;justify-content:center}
}
@media screen and (max-width: 600px) {
.passrst_myacnt .upload-container .box{width:70%}
.passrst_myacnt .formInline .upload_data_wrpr #list h1{font-size:25px}
.passrst_myacnt .myaccnt .myaccntwpr .txt_shr_bttn h2{font-size:20px}
.passrst_myacnt .myaccnt .myaccntwpr .user_update p{font-size:16px;margin-top:10px;line-height:22px}
.img_cardwrpr .img_cardSubwrpr{margin-bottom:10px}
.passrst_myacnt .myaccnt .myaccntwpr .myacntimg{flex-basis:75%;max-width:75%}
.passrst_myacnt .list_header p{font-size:26px}
}

.backend_main_wrapper .myacdashboard {
  min-height: 80vh;
}

.backend_main_wrapper td button {
  color: var(--action-icon-color);
}

.action_list tbody tr .name {
  position: relative;
}

.action_list tbody tr .name .set_priority,
.action_list tbody tr .name .name .set_priority .priorityTeam {
  position: absolute;
  /* top: 40%; */
  transform: translateY(0%);
  margin-left: 6px; 
  margin-top: -3px;
}

.backend_main_wrapper .header_menu {
  font-size: 14px;
}

.backend_main_wrapper .header_menu {
  padding: 0 19px;
  position: relative;
  border: 0;
}

.backend_main_wrapper .header_menu:hover {
  color: #fffb00;
}

.backend_main_wrapper .header_menu:after {
  content: "";
  height: 53%;
  width: 1px;
  background: #9bccfd;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.backend_main_wrapper .header_menu:last-child::after {
  display: none;
}
.modal_icon_button .noteSectionCrossBtn{
  right: -13px;
    top: -14px;
}

.cardDataSkeleton{
  margin-left: 16px;
}

@media (max-width: 1720.98px) {
.backend_main_wrapper .header_menu{padding:0 10px}
}
@media (max-width: 1580.98px) {
.backend_main_wrapper .header_menu{padding:0 10px;font-size:12px}
}
@media (max-width: 1380.98px) {
.backend_main_wrapper .header_menu{padding:0 8px;font-size:11px}
}
@media (max-width: 1260.98px) {
.backend_main_wrapper .header_menu{padding:5px 8px;font-size:14px}
.backend_main_wrapper .header_menu:after{display:none}
}
.searchBtnWraper{width:100%;display:flex;align-items:center;justify-content:center;padding:15px 0}
.searchRefreshIconBtn .MuiButtonBase-root,.searchBtnWraper .searchBtn{font-size:0;border-radius:50%;height:50px;width:50px!important;padding:0!important;min-width:inherit;display:flex;align-items:center;justify-content:center;box-shadow:3px 2px 10px #05396cf7;margin:0 8px}
.searchRefreshIconBtn .MuiButtonBase-root .MuiButton-endIcon .material-icons{font-size:32px}
.searchRefreshIconBtn .MuiButtonBase-root .MuiButton-endIcon{margin:0}
.css-1oom6tf-MuiAutocomplete-root{margin:0!important}
.audiVideoUploadBtn{
 background: #1a7de1;
    border: 0;
    display: flex;
    align-items: center;
    font-size: 24px;
    padding: 13px 20px;
    padding-top: 7px;
    border-radius: 50px;
    margin: 0 auto;
    border: 1px solid #86c2ff;
    color: #ffffff;
    /* box-shadow: 8px 7px 28px #227ede91 !important; */
    text-shadow: 1px 1px 1px #01255a;
    margin-bottom: 30px;
    outline: none;
    background: rgb(20,112,204);
    background: linear-gradient(0deg, rgba(20,112,204,1) 0%, rgba(92,173,255,1) 100%);
    box-shadow:
    inset 0 -5px 20px hsla(232, 97%, 12%, 0.4), /* top light */
    inset 0 5px 20px hsla(232, 97%, 12%, 0.4), /* bottom shadow */
    /* multiple light sources yall */
    -8px 8px 5px hsla(233, 95%, 8%, 0.15), /* drop shadow 1 */
    5px 18px 10px hsla(232, 94%, 6%, 0.2); /* drop shadow 2 */
    cursor: pointer;
    transition: transform 0.1s, box-shadow 0.1s;
    cursor: pointer;
    transition: transform 0.1s, box-shadow 0.1s;
    position: relative;
}

.audiVideoUploadBtn::before {
  content: '';
  display: block;
  position: absolute;
  left: 20px;
  right: 20px;
  top: 2px;
  height: 30px;
  border-radius: 15px;
  background: linear-gradient( hsla(0, 0%, 100%, 0.6), hsla(0, 0%, 100%, 0) );
}




.audiVideoUploadBtn svg{font-size:29px;position:relative;top:3px;margin-left:5px}
.fileVideoAudioModal h2{font-size:24px;text-transform:uppercase;background:var(--modal-title-bg2);padding:7px 18px}
.add_audio-video-hint p{font-size:20px;width:60%;text-align:center;margin:15px auto;margin-bottom:30px;font-weight:500}
.auidovideomodal .image_container .box{order:2;margin:0!important;background:#d9ecff;padding:10px;border:1px solid #add6ff}
.auidovideomodal .image_container .box span{display:none}
.auidovideomodal .image_container .box .image_name{margin:0!important;background:var(--mainbg)!important}
.auidovideomodal .image_container video{order:1;width:calc(100% - 22px);border:10px solid #d9ecff;outline:1px solid #add6ff}
.auidovideomodal .image_container .btn_container{order:3;margin-bottom:0}
.fileVideoAudioModal .react-audio-player{width:calc(100% - 40px);background:#d9ecff;padding:20px;border:1px solid #add6ff}
.audioFormWrapper{width:100%}
.auidovideomodal .image_container{margin-bottom:0}
.audioFormWrapper .formInline .submitbuttonwrapper{border-top:1px solid #bec1c5fc;padding-bottom:0}
.fileVideoAudioModal .recordgif{width:100%;margin-bottom:20px}
.submitbuttonwrapper{display:flex;justify-content:center;align-items:center;padding:15px 0;padding-bottom:0}
.priority_set .formCls{width:100%}
.priority_set .formInline{display:flex;justify-content:center;flex-wrap:wrap}
.priority_set .setPriorityTitle{width:40%}
.priority_set .priority_field_value{width:60%}
.priority_set .MuiFormControl-root{width:100%}
.priority_set .priority_field_value .MuiOutlinedInput-notchedOutline{border:1px solid #ccc;width:auto;height:40px}
.priority_set .priority_field_value .MuiOutlinedInput-input{width:100%;margin:0;text-align:left;padding:0 4%;height:40px}
.priority_set .hint{display:block;width:100%;padding:15px 0;font-size:18px;text-align:center;color:#054443}
@media screen and (max-width: 767px) {
.largeModal,.mediumModal,.smallModal{min-width:inherit;max-width:80%}
.priority_set .setPriorityTitle,.priority_set .priority_field_value{width:100%}
.priority_set .setPriorityTitle{margin-bottom:15px}
}
/* --------- End avijit css --------------- */

.backendLogoWrapper{
  background-color: #fff;
  padding: 11px;
}

.MuiLinearProgress-bar {
  background-color:var(--loder-color) !important;

}


/* ----------------- blog css start -------------------- */
.blg_crd_all_wpr {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
        margin: 0 -10px;
}
.blg_crd_all_wpr app-blog-card {
    width: calc(50% - 20px);
    margin: 10px;
}
.blogImg img{
      width: 100%;
    height: 100%;
    object-fit: cover;
}
.service_button{
  background-color: #182552;
    border: 0;
    border-radius: 0;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 20px;
    font-weight: 600;
    font-weight: var(--font-medium);
    padding: 10px 20px;
    text-decoration: none;
    border: 1px solid #75ddf2;
        margin: 0 auto;
        margin-top: 30px;
}
.heading h2{
  text-align: center;
    font-size: 40px;
    margin-bottom: 12px;
    font-weight: var(--font-bold);
    font-family: Montserrat,sans-serif;
}
.blod_video_wrapper[_ngcontent-ebx-c97] {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}
.blod_video_wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.blog_section_mainwrapper {
  margin: 15px 0;
}



.tube_sec2_subwrap app-social-share-icons{width: 100%;}
.Blog_holdr{padding:80px 0}

@media screen and (max-width: 1199px) {
.Blog_holdr{padding:40px 0}
}
@media screen and (max-width: 991px) {
.blg_crd_all_wpr app-blog-card {
    width: 100%;
    margin: 10px 0;
}
.blg_crd_all_wpr{margin: 0;}
.Blog_holdr{padding:20px 0}
.heading h2{
  margin-bottom: 10px;
}
}

/* ----------------- blog css End -------------------- */

/* ------------------video gallery start css --------------------------- */

.video_thumbnail_image_wrapper img{position:absolute;left:0;top:0;width:100%;height:100%;object-fit:cover}
app-lazyimage img{display:block;width:100%;height: 100%;}
.cardDesc h1,.cardDesc p,.cardDesc span{font-size:18px!important}
.cardDesc{margin:17px 0 16px 0!important;max-height:54px;min-height:54px}
.detail_main_wrap app-lazyimage{height:155px;overflow:hidden;display:block}
.video_wrap iframe{position:absolute;left:0;top:0;width:100%;height:100%;border:0}
.mainWrap .imgWrap app-lazyimage img{
  height: 100%;
  object-fit: cover;
}

/* ------------------- image gallery start css -----------------------  */

.chrctr_aftr_b4_wrpr{display:flex;flex-wrap:wrap;justify-content:center}
.img_b4_aftr_wrpr{width:calc(33.33% - 30px);margin:15px!important}
.aftr_b4_wrpr h2{text-align:center;font-size:40px;margin-bottom:12px;font-family: Montserrat,sans-serif;font-weight:var(--font-bold)}
.cardMainWrap{background:#dff5f9!important;border:1px solid #f6feff;box-shadow:4px 7px 8px #00556440!important;}
.aftr_b4_wrpr h1, .blog_subwrapper h1, .heading h1 {
    text-align: center;
    margin-bottom: 12px;
    font-weight: var(--font-semibold);
    font-weight: 800;
}

/* ------------------- image gallery end css -----------------------  */

/* ------------------- Frontend testimonial css -----------------------  */

.testimonials_block{margin-bottom:35px;margin-top:35px;position:relative}
.testimonials_heading{font-family:Montserrat,sans-serif;font-size:40px;font-weight:800;text-align:center;margin-bottom: 12px;}
.testilist_wrpr{border-radius:5px;box-sizing:border-box;display:flex;flex-direction:row;flex-wrap:wrap;height:100%;justify-content:center;margin:0;padding:30px 10px;position:relative}
.img_title_wrap h2{background-color:#31b5ae;color:#fff;font-size:32px;margin:20px 0 0!important;padding:22px 10px 0}
.detail_main_wrap app-lazyimage{height:inherit}
.testiImg app-lazyimage img{height:100%;object-fit:cover}
.add_testimonial_btnwrapper {
  display: flex;
  justify-content: flex-end;
}
.add_testimonial_btnwrapper button, .add_testimonial_btnwrapper button:hover {
  background: #0c1f70;
  border: 1px solid #0c1f70;
  color:#fff;
  font-size: 17px;
  margin-right: 10px;
  margin-top: 4px;
  padding: 8px 15px;
  text-shadow: 1px 1px 1px #009ddb;
  transition: all .3s;
}



/* ------------------- Frontend testimonial modal css -----------------------  */


.modalclose {
  position: absolute;
  right: -20px;
  top: -20px;
  height: 40px;
  width: 40px;
  border: 0;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: var(--close-btn-bg);
  color: var(--close-btn-color);
}

.common-modalbox {
  border: 7px solid var(--common-modalbox-border-color);
  border-radius: 8px;
  max-height: inherit;
  overflow-y: auto;
  position: relative !important;
  overflow: inherit;
}

.video-modalbox video {
  width: 100%;
}

.youtubeVideo-modalbox .blod_video_wrapper {
  position: relative;
  padding-bottom: 56.25%;
  margin-bottom: 15px;
}

.common-modalbox .mat-dialog-container {
  padding: 20px;
  border-radius: 0;
  max-height: 98vh;
  overflow-y: auto;
}

.common-modalbox.audio-modalbox {
  max-width: var(--audio-modal-max-width) !important;
  min-width: 500px;
}

.common-modalbox.video-modalbox {
  max-width: var(--video-modal-max-width) !important;
  min-width: 500px;
}

.common-modalbox.youtubeVideo-modalbox {
  max-width: var(--youtubeVideo-modal-max-width) !important;
  min-width: 500px;
}

.audio_subwrapper {
  width: 100%;
}

.audio_subwrapper audio {
  width: 100%;
  background: var(--audio-bg);
  box-shadow: var(--audio-box-shadow);
  border-radius: var(--audio-border-radius);
  overflow: inherit;
  border: 3px solid #f3f3f3;
  border-width: var(--audio-border-width);
  border-color: var(--audio-border-color);
  border-style: solid;
}

.audio_subwrapper figure {
  padding: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.audio-modalbox .mat-dialog-content {
  max-width: 100% !important;
  padding: 0;
  margin: 0;
  overflow: inherit;
}

.audio_subwrapper audio::-webkit-media-controls-panel {
  background: var(--audio-tag-bg);
  border-radius: 0;
}

.audio_subwrapper audio::-webkit-media-controls-mute-button {
  background-color: var(--audio-tag-button-color);
  border-radius: 50%;
  color: #fff;
}

.audio_subwrapper audio::-webkit-media-controls-play-button {
  background-color: var(--audio-tag-button-color);
  border-radius: 50%;
  color: #fff;
}

.audio_subwrapper audio::-webkit-media-controls-timeline-container {
  background-color: red;
  color: red;
}

.audio_subwrapper audio::-webkit-media-controls-current-time-display {
  color: var(--audio-tag-text-color);
}

.audio_subwrapper audio::-webkit-media-controls-time-remaining-display {
  color: var(--audio-tag-text-color);
}


.cardDesc p {
  cursor: pointer;
}

.contWrap h1 {
  cursor: pointer;
}

app-video-card .imgWrap {
  cursor: pointer;
}

a,
button,
i {
  cursor: pointer;
}

/* ------------------- Frontend testimonial end css -----------------------  */

/* -------------------  Progress bar css -----------------------  */

 .backendheader_bar{background-color:var(--mainbg)!important}
 .MuiLinearProgress-root{
  margin: 4px;
}

/* --------------addTestimonial--responsive-----rjdp----------------- */
.testimonials_banner img {width: 100%;}
.testiMo_tblwrpr{padding: 5px;}
.testiMo_tblwrpr .testiMo_wrpr .list_search_item div {min-width: 0%;}
.testiMo_wrpr input{height: auto ;}
.testiMo_wrpr .submitbuttonwrapper{width: 50% !important; justify-content: flex-start !important;}
.testiMo_wrpr .link_container {flex-direction: row ;}



@media(max-width:1199px){
.testiMo_wrpr .rating { flex: 1 0 auto !important; }
.testiMo_wrpr .submitbuttonwrapper {width: 70% !important;}
}
@media(max-width:680px){
  .testiMo_wrpr .countDiv__column--col6 {
    flex: 1 0 98% !important;
}
}
@media(max-width:480px){
.testiMo_wrpr .link_container .link_input_container {
    flex-wrap: wrap;
}
.testiMo_wrpr .MuiInputAdornment-root {white-space:inherit; margin-right: -39px;}

.testiMo_wrpr .link_container .linkid_enter { margin-bottom: 15px; width: 100%;}
.testiMo_wrpr .submitbuttonwrapper {
  width: 100% !important; justify-content: center !important;
}
.list_header{ font-size: 24px; margin-bottom: 15px;}
}
@media(max-width:360px){
  .testiMo_wrpr .MuiInputAdornment-root p{font-size: 13px;}
  .testiMo_wrpr .submitbuttonwrapper .MuiButtonBase-root{ font-size: 16px; line-height: 24px; padding: 8px 10px;}
  .testiMo_wrpr .submitbuttonwrapper .MuiButtonBase-root:first-child{margin-right: 0;} 
  .testiMo_wrpr .link_container input { padding: 26.5px 14px;}
}
/* --------------addTestimonial--responsive-----rjdp----------------- */
/* --------for dashbord image modal------- */
.recentlyImageModl{
  width: 500px !important;
}

/* -------for resent upload videos desciption-------- */
.image_card_description {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
/* ------for shorting arrows---------- */

.backend_main_wrapper .MuiTableCell-root .Mui-active .MuiTableSortLabel-icon {
  color: #fff;
  opacity: 1;
}
.backend_main_wrapper .MuiTableCell-root .MuiTableSortLabel-icon {
  opacity: .5;}

  .react-share__ShareButton {
    margin: 0 2px !important;
}

.backend_main_wrapper .MuiTableCell-root .Mui-active .MuiTableSortLabel-icon {
  opacity: 1;
  color: #fff;
}

.loaderClass{
  margin-top: 10px;
}

/* ---------css for new notes module----------------  */
.MuiBadge-badge {
  background-color: #167e9e !important;
}

.notes_header {
  background: linear-gradient(90deg, var(--mainbg) 100%, #fff2d0 0);
  font-size: 25px;
  max-height: 70px;
  text-align: center;
  color: #fff;
}
.content_container {
  background: linear-gradient(139deg, #dfe8ed, #c9d5df);
  padding: 20px 24px !important;
}
.textarea_add_note {
  background-color: #f0f0f0 !important;
  border: 1px solid #9a9a9a;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  flex: 1 1;
  font-size: 20px;
  height: 50px !important;
  margin-bottom: 0;
  margin-top: 10px;
  overflow: hidden;
  height: 100px !important;
  width: 786px;
}
.action_btn_container .MuiButtonBase-root {
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: var(--mainbg);
  margin-right: 8px;
  font-size: 20px;
  line-height: 26px;
  padding: 8px 20px;
  text-transform: capitalize;
  box-shadow: 2px 3px 8px #225a918f !important;
  border: 1px solid var(--button-border-color);
  text-transform: capitalize;
}
.action_btn_container {
  justify-content: center !important;
}

.action_btn_container .submit_btn:hover,
.action_btn_container .close_btn:hover {
  background-color: #188b9f;
  color: #fff;
}
.MuiTableCell-root .MuiTableSortLabel-icon {
  opacity: 0.5;
}
/* .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
  opacity: 1 !important;
  color: rgb(0 0 0) !important;
} */


/* notess new css */
.notesbody .MuiPaper-rounded{
  background-color: #fff;
  padding: 20px;
  border: 5px solid var(--modal-border);
  border-radius: 5px;
  margin: 40px auto;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  outline: none;

  max-width: 30%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
}

.notesbody .notes_header{
  background: none !important;
}

.notesbody .notes_header{
  flex-direction: column;
      padding: 0 !important;
      max-height: none;
}

.notesTable .MuiPagination-root{
  margin: 6px;
  margin-top: 16px;
} 

/* --------- End avijit css --------------- */

.backend_main_wrapper .ArrowDownwardIcon {
  opacity: 5;
  color: rgb(0 0 0);
}

.backend_main_wrapper .MuiTableCell-root .MuiTableSortLabel-icon {
  opacity: 0.5;
}

.backend_main_wrapper .MuiTableCell-root .Mui-active .MuiTableSortLabel-icon {
  opacity: 1;
  color: #fff;
}

.MuiToolbar-root {
  min-height: 47px !important;
}

.MuiDialog-root {
  z-index: 1200 !important;
}

.noteslist
  .tablePagination
  .MuiToolbar-gutters
  .MuiButtonBase-root.Mui-disabled
  svg {
  color: rgba(0, 0, 0, 0.26);
}

/* .notes_details .close_icon_btn {
  border-radius: 50% !important;
  height: 30px;
  opacity: 1;
  padding: 5px !important;
  position: absolute !important;
  right: -14px;
  top: -17px;
  width: 30px;
  background-color: #fff;} */
  .notes_details .MuiIconButton-root:hover {
    background-color:#fff !important;
}

 .notes_details .MuiIconButton-root:hover {
    background-color:#fff !important;
}
.muiTable .sort_btn{

  color: #fff;
}
.notes_details .sort_btn{
  color: #000;
}

.login_main_wrpper .MuiPaper-root {
  background-color: #d1e49a;
  border-radius: 25px

;
}

/* ------------for Custom Meta----------- */
/* .coustom_meta{
  display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.coustom_meta .coustom_meta1{
  background: #04bfff !important;
    border-radius: 50% !important;
    width: 36px !important;
    height: 36px !important;
    color: #fff !important;
    margin-right: 5px;
}

.coustom_meta .coustom_meta2{
  background: #04bfff !important;
    border-radius: 50% !important;
    width: 20px !important;
    height: 20px !important;
    color: #fff !important;
}

.aftr_b4_wrpr {
  text-align: center;
 
  display: flex;
  justify-content: space-between;
}

.heading {
  text-align: center;
 
  display: flex;
  justify-content: space-between;
} */

.add_testimonial_btnwrapper .blog_details{
  margin-bottom: 25px!important;
}

.add_testimonial_btnwrapper .image_details{
  margin-bottom: 25px!important;
}

.add_testimonial_btnwrapper .video_details{
  margin-bottom: 25px!important;
}

textarea{
resize: none;
}