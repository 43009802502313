.meetourproviders_banner_sec .banner_con {    background-image: url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/meetProviders_bnnr_chrctr_img.webp);
    padding: 125px 0 95px;
    background-position: right 208px bottom -50px;}
.meetourproviders_banner_sec .banner_wrpr_bg {  background-position: left 51% bottom 45px,top 21% left 0%,top 45% right 7%;}

@media only screen and (max-width:1440px){
    .meetourproviders_banner_sec .banner_con_text1 h1 {
        font-size: 90px;
        line-height: 90px;

    }

    .meetourproviders_banner_sec .banner_con{
        padding: 117px 0 95px;

        background-position: right 208px bottom -110px;

    }
}

@media only screen and (max-width:1199px){
    .meetourproviders_banner_sec .banner_con {
        
        background-position: center top 20px;
        background-repeat: no-repeat;
        background-size: 77%;
        min-height: inherit;
        padding-top: 60%;
        padding-bottom: 0;
    }
    .meetourproviders_banner_sec .banner_con_text1 h1 br {display: none;}
    .meetourproviders_banner_sec .banner_con_text1 h1 {
        font-size: 50px;
        line-height: 50px;
    }
    .meetourproviders_banner_sec .banner_con_text {
        padding-bottom: 15px;
    }
}

@media only screen and (max-width: 767px){

    .meetourproviders_banner_sec .banner_con_text1 h1 {
        font-size: 32px;
        line-height: 40px;
    }
}
