.peri_artery_block2 {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/pharmagenomics_block2_hexa.webp),url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/peri_artery_block2_bg_chrctr_img.webp),url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/pharmagenomics_block2_green_bg.webp);
    background-size: auto, auto 100%,cover;
    background-position: right top, left -20px top, center;
    background-repeat: no-repeat;
    padding: 95px 0;position: relative;
}


.peri_artery_block2 .peri_block2_abs_img {
    position: absolute;
    left: 0;
    bottom: -28px;
    width: 40%;
}

.peri_artery_block2_inner_wrpr {
    padding-left: 551px;
}

.peri_artery_block2 .textwrapper h2 {
    font-size: 48px;
    margin-bottom: 27px;
}
.peri_artery_block2 .textwrapper p { padding-right: 85px; }
.peri_artery_block2 .textwrapper h2, .peri_artery_block2 .textwrapper p {color: #fff;}


.peri_artery_block3 {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/pharmagenomics_block3_blue_bg.webp);background-repeat: no-repeat;background-size: cover;background-position: center; padding: 96px 0;position: relative;

}
.peri_artery_block3 .textwrapper {padding-right: 50%;}
.peri_artery_block3 .textwrapper p {color: #fff; margin-bottom: 25px;}

.peri_artery_block3 .textwrapper p:last-child{margin-bottom: 0;}

.peri_artery_block3_abs {
    position: absolute;
    right: 0;
    top: 41%;
    width: 47%;
    transform: translateY(-50%);
}

@media only screen and (max-width:1800px){
.peri_artery_block2 .textwrapper p {
    padding-right: 0;
}
}
@media only screen and (max-width:1540px){
    .peri_artery_block2 .textwrapper h2 {
        font-size: 37px;
        margin-bottom: 12px;
    }
    .peri_artery_block2{
        padding: 73px 0;
    }

    .peri_artery_block3_abs{
        top: 43%;
    }

    .peri_artery_block2_inner_wrpr {
        padding-left: 499px;
    }
}

@media only screen and (max-width:1359px){
   
    .peri_artery_block2{
        padding: 40px 0;
    }
    .peri_artery_block2_inner_wrpr {
        padding-left: 449px;
    }

    .peri_artery_block3_abs {
        top: 44%;
    }
}

@media only screen and (max-width:1261px){
    .peri_artery_block2 .textwrapper h2 {
        font-size: 34px;
        margin-bottom: 12px;
    }

    .peri_artery_block3{
        padding: 70px 0;
    }
}

@media only screen and (max-width: 1199px){
    .peri_artery_block2 {
        background-image: url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/pharmagenomics_block2_green_bg.webp);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 30px 0;
    }

    .peri_artery_block2 .textwrapper h2 {
        font-size: 42px;}
    .peri_artery_block2 .peri_block2_abs_img{display: none;}
    .peri_artery_block2_inner_wrpr {
        padding-left: inherit;
    }


    .peri_artery_block3{
        padding: 30px 0;
    }
    .peri_artery_block3_inner_wrpr {
        flex-direction: column;
        display: flex;
    }

    .peri_artery_block3_abs {
        display: block;
        position: relative;
        right: 0;
        width: 100%;
        transform: inherit;
        top: inherit;
    }

    .peri_artery_block3 .textwrapper {
        padding-right: 0;
        margin-bottom: 15px;
        
    }

    .peri_artery_block3 .textwrapper p {
        
        margin-bottom: 15px;
    }

}
@media only screen and (max-width: 767px){

    .peri_artery_block2 .textwrapper h2 {
        font-size: 32px;
        line-height: 38px;
    }

    .peri_artery_block2 {padding: 20px 0 30px;}

}

@media only screen and (max-width: 680px){
    .peri_artery_block2{
        padding: 15px 0;
    }

    .peri_artery_block3 {
        padding: 15px 0;
    }
}
