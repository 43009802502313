

.upload_data_wrpr{
  width: 100%;
}
.blog_img_wrpr{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
#list{
  margin-right: 10px;
}
.image_container{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
/* .image_container div{
  width: 32.5% !important;
} */
.image_container .image_preview{    
  width: 100%;
  /* height: 300px; */
  display: block;
}
/* .box{
  height: 500px !important;
  display: flex;
} */
.content_wrpr {
  margin: 0 10px;
  width: 20%;
  background: #ddd;
  padding: 10px;
  border: 1px solid #c4c4c4;
}
.content_wrpr .cropper-container {width: 100% !important;}
.cropimagebtn {
  background: #e95e14 !important;
  display: block !important;
  margin: 0 auto !important;
  margin-top: 10px !important;
  color: #fff !important;
  border: 1px solid #fb5f0c !important;
  box-shadow: 5px 4px 6px #b9470a57 !important;
}

/* .btn_container .css-sghohy-MuiButtonBase-root-MuiButton-root {background: #e0bbaf!important;
  background: linear-gradient(180deg,rgba(224,187,175,1) 0%,rgba(213,170,156,1) 50%,rgba(206,155,139,1) 50%,rgba(192,135,117,1) 100%)!important;} */

  .box {
      margin: 0 10px;
      width: 20%;
      background: #ddd;
      padding: 10px;
      border: 1px solid #c4c4c4;
  }

.image_name {
      display: block;
  }

  .btn_container {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 20px 0;
  }
  .image_name {
      display: block;
      background: #e95e14;
      padding: 10px;
      margin-top: 10px;
      color: #fff;
  }


  .cropimagewrapper {
      max-height: 382px;
      overflow: hidden;
  }

  .link_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .link_input_container{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .link_save_btn{
    margin-left: 10px !important;
  }

  .link_thumbnail_container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }


  .each_link_container{
    display: flex;
    position: relative;
  }


  .link_thumbnail {
    height: 150px;
  }

  .link_thumbnail_container{
    height: 148px;
  }

  .linkid_remove{
    position: absolute;
    margin: auto;
    right: 0;
    background-color: #fff;
    opacity: 0.8;
    border-radius: 50%;
  }
  /* .linkid_remove button{
    position: absolute;
    left: -35px;
  } */
  .linkid_play{
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    background-color: #fff;
    opacity: 0.6;
    border-radius: 50%;
  }

  .linkid_remove button{
   padding: 0 0;
   
  }

  .linkid_remove button svg{
    width: 20px;
    height: 20px;
  }

  .linkid_play button{
    padding: 0 0;
    
   }
 
   .linkid_play button svg{
     width: 40px;
     height: 40px;
   }

   .dialog_container{
    padding: 2px !important;
    position: relative;
   }

   .dialog_close_btn{
    position: absolute !important;
    top: 6px;
    right: 6px;
    background-color: #fff !important;
    padding: 3px !important;
   }


   /* ------------------------- Chip Text Box ------------------------------ */
   .chip_textbox_container{
    display: flex;
    flex-direction: column;
    align-items: center;
   }
   .chip_textbox_input_container{
    display: flex;
    justify-content: center;
    align-items: center;
   }
   .chips_container{
    margin-top: 10px;
   }

   .drawer_chip_list{
    display: flex;
   }
   .drawer_chip_item{
    margin: 0 20px;
   }

   /* -------------------------------------------- File Uploader Icon Pack ---------------------------------------- */
   .pdf{
    background-image:url("./assets/pdf_icon.png") ;
    background-size: contain;
    width: 70px; 
    height: 70px; 
    display:block
  }
  .doc{
    background-image:url("./assets/doc_icon.png") ;
    background-size: contain;
    width: 70px; 
    height: 70px; 
    display:block
  }
  
  .audio,.mpeg {
    background-image:url("./assets/audio_icon.png") ;
    background-size: contain;
    width: 70px; 
    height: 70px; 
    display:block
  }
  .video,.mp4{
    background-image:url("./assets/video_icon.png") ;
    background-size: contain;
    width: 70px; 
    height: 70px; 
    display:block
  }
  .datahasdiv{
    text-align: center;
    cursor: pointer;
  }
  .datahasdiv img{
    width: 60px;
  }

  @media(max-width:1199px){
    .box{width: 36%;}
    .content_wrpr{width: 36%;}

  }

  @media(max-width:560px){
    .box{width: 98%;}
    .content_wrpr{width: 98%; margin-bottom: 15px;}

  }