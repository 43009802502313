.all_images img {
  display: block;
  width: 100%;
}
.abbaner .banner_con {
  background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/hpcbannerimg+(1).webp");
  background-position-x: 95%;
}
.abbaner_block2 .textwrapper h2 {
  font-size: 48px;
}
.abbaner_block2 .textwrapper p {
  font-size: 22px;
}
.abbaner_block2_subwrapper {
  padding: 60px 0;
  padding-left: 530px;
}
.abbaner_block2_subwrapper .abimage {
  left: 0;
}
.advance_banner .banner_header{
  font-size: 50px;
  color: rgb(11,31,114);

}

.abbaner_block3 {
  /* padding: 30px 0;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/block3backgrund1.webp),url(https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/hpcblock3img.webp);
    background-repeat: no-repeat;
    background-position: left,right;
    background-size: 50%,contain; */
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/hpcblock3img.webp);
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: relative;
  padding: 0;
  background-color: transparent;
}

.abbaner_block3 .abbaner_block3_textwraper {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/block3backgrund1.webp);
  background-position: 0 0;
  background-repeat: no-repeat;

  position: relative;
  background-size: 57% 100%;
}
.abbaner_block3 .abimage {
  height: 100%;
}
.abbaner_block3 .block_listwrapper p {
  font-size: 18px;
}
.abbaner_block3 .block_listwrapper p span {
  color: #0be2e5;
}
.abbaner_block3 .block_listwrapper p::after {
  content: "";
  height: 20px;
  width: 20px;
  border-radius: unset;
  border: none;
  background-repeat: no-repeat;
  position: absolute;
  background-color: transparent;
  left: 0;
  background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/arrowpng+.webp");
  padding-right: 730px;
}

.abbaner_block3 .abbaner_block3_textwraper .textwrapper {
  padding: 30px 0;
  padding-right: 730px;
}
.textimagewrapper {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/block3backgrund1.webp);
  background-position: 0 0;
  background-repeat: no-repeat;

  position: relative;
  background-size: 66% 100%;
}

.abbaner_block4 {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/block4img.webp);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0;
  position: relative;
}

.abbaner_block4 .abimage1 {
  position: absolute;
  left: 180px;
  bottom: 0;
  display: block;
}
.abbaner_block4 .textwrapper {
  position: relative;
  width: 816px;
  margin: 0 0 0 auto;
  z-index: 1;
}
.abbaner_block4 .textwrapper p {
  font-weight: 500;
}

.abbaner_block4 .textwrapper .banner4_button {
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background: linear-gradient(rgb(29,189,193),rgb(28,194,68));
  font-weight: 600;
  border: none;
}

.abbaner_block4 .textwrapper .abimage {
  position: absolute;
  right: -50px;
  top: 0px;
  z-index: -1;
  width: 25%;
}



@media only screen and (max-width: 1199px) {
  .abbaner .banner_con {
    background-position-x: 0;
  }
  .abbaner .banner_con {
    background-size: 100%;
    background-position: top;
  }
  .advance_banner .banner_header{
    font-size: 50px;
    color: rgb(11,31,114);
  
  }
  .abbaner_block2_subwrapper {
    padding-left: 0;
  }

  .abbaner_block3 .textwrapper {
    padding-right: 0 !important;
  }
  .abbaner_block3 .abimage {
    right: 0 !important;
  }
  .abbaner .banner_con_text {
    border-top: none;
  }
  .abbaner .banner_con_text h1 {
    width: auto !important;
  }
  .abbaner_block3 {
    padding-top: 70%;
    background-position: top;
    background-size: 100%;
  }
  .abbaner_block3 .abbaner_block3_textwraper {
    background-size: 100% 100%;
  }
  .abbaner_block4 {
    padding: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
    background-position: top;
    background-size: auto;
    background-color: transparent;
    background: linear-gradient(
      180deg,
      rgba(184, 233, 240, 1) 0%,
      rgba(189, 248, 223, 1) 100%
    );
  }
  .abbaner_block4 .common_width .textwrapper {
    width: 100%;
    
  }
  .abbaner_block4 .abimage1 {
    position: relative;
    bottom: -40px;
    margin: 0 auto;
    width: 60%;
  }
  .abbaner_block4 .textwrapper .abimage {
    display: block !important;
    top: 0;
    right: 0;
    rotate: 296deg;
  }
  .abbaner_block4 .abimage1 {
    width: 110% !important;
    left: 0 !important;
  }
  .abbaner_block2_subwrapper {
    padding: 30px 0;
  }
  .abbaner_block4 .abimage1 {
    margin-bottom: 70px;
    width: 120% !important;
    left: -27px !important;
  }
}
@media only screen and (max-width: 1800px) {
  .abbaner_block4 .abimage1 {
    left: 100px;
  }
  .advance_banner .banner_header{
    font-size: 40px;
    color: rgb(11,31,114);
  
  }
}
@media only screen and (max-width: 1700px) {
  .abbaner_block3 {
    background-position-x: 110%;
  }
  .advance_banner .banner_header{
    font-size: 35px;
    color: rgb(11,31,114);
  
  }
}
@media only screen and (max-width: 1760px) {
  .abbaner h1 {
    width: 50%;
  }
}
@media only screen and (max-width: 1632px) {
  .abbaner_block4 .textwrapper .abimage {
    display: none;
  }
  .abbaner_block4 .textwrapper {
    width: 665px;
  }
  .textwrapper p {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 1600px) {
  .abbaner .banner_con_text h1 {
    width: 60%;
  }
}

@media only screen and (max-width: 1500px) {
  .abbaner_block3 {
    background-position-x: 137%;
  }
}
@media only screen and (max-width: 1560px) {
  .abbaner_block3 .abimage {
    right: -200px;
  }
  .abbaner_block3 .abimage {
    width: 60%;
  }
}
@media only screen and (max-width: 1460px) {
  .abbaner .banner_con_text h1 {
    width: 50%;
  }
  .advance_banner .banner_header{
    font-size: 30px;
    color: rgb(11,31,114);
  
  }

  .abbaner_block4 .abimage1 {
    left: 50px;
    width: 45%;
  }
  .abbaner_block4 .textwrapper {
    width: 601px;
  }

  .abbaner_block3 {
    background-position-x: 139%;
  }
  .abbaner_block3 .abbaner_block3_textwraper .textwrapper {
    padding-right: 642px;
  }
}
@media only screen and (max-width: 1450px) {
  .abbaner_block3 .textwrapper {
    padding-right: 600px;
  }
}

@media only screen and (max-width: 1380px) {
  .abbaner_block2 .textwrapper p {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1350px) {
  .abbaner_block3 .abbaner_block3_textwraper .textwrapper {
    padding-right: 595px;
  }
  .abbaner_block3 {
    background-position-x: 150%;
  }
}
@media only screen and (max-width: 1290px) {
  .abbaner_block3 {
    background-position-x: 160%;
  }
  .advance_banner .banner_header{
    font-size: 28px;
    color: rgb(11,31,114);
  
  }
  .abbaner_block3 .abbaner_block3_textwraper .textwrapper {
    padding-right: 571px;
  }
}
@media only screen and (max-width: 1250px) {
  .abbaner_block3 {
    background-position-x: 175%;
  }
  .abbaner_block3 .abbaner_block3_textwraper .textwrapper {
    padding-right: 533px;
  }
  .abbaner_block4 .abimage1 {
    left: 10px;
  }
}

@media only screen and (max-width: 399px){
  .abbaner_block4{
    padding: 10px;
  }
}
