.woundHomeBlock {
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/woundbg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 160px 50px 200px;
    position: relative;

}



.wound_common_width {
   
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wtextwrapper{
    width: 60%;
}



.woundHomeBlock .wtextwrapper h2 {
    color: rgb(11, 226, 229);
    margin-bottom: 10px;
    font-size: 42px;
    line-height: 50px;
    font-weight: 700;
}

.wtextwrapper p {
    font-size: 30px;
    color: white;
    font-weight: 600;
    line-height: 35px;
}

.woundHomeBlock .wtextwrapper h3 {
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 30px;
    color: #fff000;
    line-height: 34px;
    margin-top: 20px;
}


.wound_list_wrap p {
    display: flex;
    font-size: 22px;
    font-weight: 300;
}

.wound_list_wrap p img {
    height: 35px;
    width: 30px;
    margin-top: 0px;

}


.wound_img_wrap {
    
}

@media screen and (max-width:1799px) {


    .wound_common_width {
        

    }

    .woundHomeBlock .wtextwrapper h2 {
        color: rgb(11, 226, 229);
        margin-bottom: 10px;
        font-size: 40px;
        line-height: 50px;
        font-weight: 700;
    }

    .wtextwrapper p {
        font-size: 28px;
        color: white;
        font-weight: 600;
        line-height: 35px;
    }

    .woundHomeBlock .wtextwrapper h3 {
        font-weight: 600;
        font-size: 30px;
        line-height: 34px;
        margin-top: 20px;
    }


    .wound_list_wrap p {
        display: flex;
        font-size: 22px;
        font-weight: 300;
    }
 


}

@media screen and (max-width:1699px) {


  

    .woundHomeBlock .wtextwrapper h2 {
        color: rgb(11, 226, 229);
        margin-bottom: 10px;
        font-size: 38px;
        line-height: 50px;
        font-weight: 700;
    }

    .wtextwrapper p {
        font-size: 26px;
        color: white;
        font-weight: 600;
        line-height: 35px;
    }

    .woundHomeBlock .wtextwrapper h3 {
        margin-bottom: 23px;
        font-weight: 600;
        font-size: 30px;
        color: #fff000;
        line-height: 34px;
        margin-top: 20px;
    }


    .wound_list_wrap p {
        display: flex;
        font-size: 22px;
        font-weight: 300;
    }


}

@media screen and (max-width:1599px) {

    .woundHomeBlock {
     
        padding: 50px 150px;
       
    
    }
 

    .woundHomeBlock .wtextwrapper h2 {
        color: rgb(11, 226, 229);
        margin-bottom: 10px;
        font-size: 36px;
        line-height: 50px;
        font-weight: 700;
    }

    .wtextwrapper p {
        font-size: 24px;
        color: white;
        font-weight: 600;
        line-height: 35px;
    }

    .woundHomeBlock .wtextwrapper h3 {
        margin-bottom: 23px;
        font-weight: 600;
        font-size: 26px;
        color: #fff000;
        line-height: 34px;
        margin-top: 20px;
    }


    .wound_list_wrap p {
        display: flex;
        font-size: 22px;
        font-weight: 300;
    }

 

}

@media screen and (max-width:1499px) {


  

    .woundHomeBlock .wtextwrapper h2 {
        color: rgb(11, 226, 229);
        margin-bottom: 10px;
        font-size: 34px;
        line-height: 50px;
        font-weight: 700;
    }

    .wtextwrapper p {
        font-size: 22px;
        color: white;
        font-weight: 600;
        line-height: 35px;
    }

    .woundHomeBlock .wtextwrapper h3 {
        margin-bottom: 23px;
        font-weight: 600;
        font-size: 24px;
        color: #fff000;
        line-height: 34px;
        margin-top: 20px;
    }


    .wound_list_wrap p {
        display: flex;
        font-size: 20px;
        font-weight: 300;
    }

  

}

@media screen and (max-width:1399px) {

  

  

    .woundHomeBlock .wtextwrapper h2 {
        color: rgb(11, 226, 229);
        margin-bottom: 0px;
        font-size: 32px;
        line-height: 35px;
        font-weight: 700;
    }

    .wtextwrapper p {
        font-size: 20px;
        color: white;
        font-weight: 600;
        line-height: 25px;
        margin-top: 10px;
    }

    .woundHomeBlock .wtextwrapper h3 {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 22px;
        color: #fff000;
        line-height: 34px;
        margin-top: 10px;
    }


    .wound_list_wrap p {
        display: flex;
        font-size: 18px;
        font-weight: 300;
    }

  

}


@media screen and (max-width:1299px) {



  

   .woundHomeBlock .wtextwrapper h2 {
        color: rgb(11, 226, 229);
        margin-bottom: 0px;
        font-size: 32px;
        line-height: 35px;
        font-weight: 700;
    }

    .wtextwrapper p {
        font-size: 20px;
        color: white;
        font-weight: 600;
        line-height: 30px;
    }

    .woundHomeBlock .wtextwrapper h3 {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 22px;
        color: #fff000;
        line-height: 30px;
        margin-top: 10px;
    }


    .wound_list_wrap p {
        display: flex;
        font-size: 18px;
        font-weight: 300;
    }

  

}

 



@media screen and (max-width:1199px) {

    .woundHomeBlock {
        padding: 40px;

    }
    .wtextwrapper{
        width: 100%;
        margin-top: 20px;
    }

    .wound_common_width {
       
        display: flex;
        flex-direction: column-reverse;
       

    }

    .woundHomeBlock .wtextwrapper h2 {
        color: rgb(11, 226, 229);
        margin-bottom: 10px;
        font-size: 40px;
        line-height: 50px;
        font-weight: 700;
        white-space: nowrap;
    }

    .wtextwrapper p {
        font-size: 28px;
        color: white;
        font-weight: 600;
        line-height: 35px;
    }

    .woundHomeBlock .wtextwrapper h3 {
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 28px;
        color: #fff000;
        line-height: 34px;
        margin-top: 20px;
    }


    .wound_list_wrap p {
        display: flex;
        font-size: 22px;
        font-weight: 300;
    }

 

  

}

@media screen and (max-width:999px) {


.woundHomeBlock{
    
}
 

    .woundHomeBlock .wtextwrapper h2 {
        color: rgb(11, 226, 229);
        margin-bottom: 10px;
        font-size: 38px;
        line-height: 50px;
        font-weight: 700;
        white-space: nowrap;
    }

    .wtextwrapper p {
        font-size: 28px;
        color: white;
        font-weight: 600;
        line-height: 33px;
    }

    .woundHomeBlock .wtextwrapper h3 {
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 28px;
        color: #fff000;
        line-height: 34px;
        margin-top: 20px;
    }


    .wound_list_wrap p {
        display: flex;
        font-size: 22px;
        font-weight: 300;
    }



}


@media screen and (max-width:899px) {

 
    .wound_common_width {
       

    }

    .woundHomeBlock .wtextwrapper h2 {
        color: rgb(11, 226, 229);
        margin-bottom: 10px;
        font-size: 32px;
        line-height: 50px;
        font-weight: 700;
        white-space: nowrap;
    }

    .wtextwrapper p {
        font-size: 22px;
        color: white;
        font-weight: 600;
        line-height: 33px;
        
    }

    .woundHomeBlock .wtextwrapper h3 {
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 24px;
        color: #fff000;
        line-height: 34px;
        margin-top: 20px;
        white-space: nowrap;
    }


    .wound_list_wrap p {
        display: flex;
        font-size: 22px;
        font-weight: 300;
        white-space: nowrap;
    }



}

@media screen and (max-width:799px) {

 

    .wound_common_width {
       
       
     

    }

    .woundHomeBlock .wtextwrapper h2 {
        color: rgb(11, 226, 229);
        margin-bottom: 10px;
        font-size: 32px;
        line-height: 50px;
        font-weight: 700;
        white-space: nowrap;
    }

    .wtextwrapper p {
        font-size: 22px;
        color: white;
        font-weight: 600;
        line-height: 33px;
    }

    .woundHomeBlock .wtextwrapper h3 {
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 24px;
        color: #fff000;
        line-height: 34px;
        margin-top: 20px;
        white-space: nowrap;
    }


    .wound_list_wrap p {
        display: flex;
        font-size: 22px;
        font-weight: 300;
        white-space: nowrap;
    }

 

}


@media screen and (max-width:699px) {



    .wound_common_width {
        
    
    }

  

    .woundHomeBlock .wtextwrapper h2 {
        color: rgb(11, 226, 229);
        margin-bottom: 10px;
        font-size: 28px;
        line-height: 50px;
        font-weight: 700;
        
    }

    .wtextwrapper p {
        font-size: 20px;
        color: white;
        font-weight: 600;
        line-height: 33px;
        
    }

    .woundHomeBlock .wtextwrapper h3 {
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 22px;
        color: #fff000;
        line-height: 34px;
        margin-top: 20px;
        white-space: nowrap;
    }


    .wound_list_wrap p {
        display: flex;
        font-size: 20px;
        font-weight: 300;
        white-space: nowrap;
    }



  
}

@media screen and (max-width:599px) {



    .wound_common_width {
      
       

    }

    .woundHomeBlock .wtextwrapper h2 {
        color: rgb(11, 226, 229);
        margin-bottom: 10px;
        font-size: 26px;
        line-height: 50px;
        font-weight: 700;
        
    }

    .wtextwrapper p {
        font-size: 20px;
        color: white;
        font-weight: 600;
        line-height: 33px;
        
    }

    .woundHomeBlock .wtextwrapper h3 {
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 20px;
        color: #fff000;
        line-height: 34px;
        margin-top: 20px;
        white-space: nowrap;
    }


    .wound_list_wrap p {
        display: flex;
        font-size: 18px;
        font-weight: 300;
        white-space: nowrap;
    }



  
}

@media screen and (max-width:699px) {



    .wound_common_width {
        
    
    }

  

    .woundHomeBlock .wtextwrapper h2 {
        color: rgb(11, 226, 229);
        margin-bottom: 10px;
        font-size: 28px;
        line-height: 50px;
        font-weight: 700;
        
    }

    .wtextwrapper p {
        font-size: 20px;
        color: white;
        font-weight: 600;
        line-height: 33px;
        
    }

    .woundHomeBlock .wtextwrapper h3 {
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 22px;
        color: #fff000;
        line-height: 34px;
        margin-top: 20px;
        white-space: nowrap;
    }


    .wound_list_wrap p {
        display: flex;
        font-size: 20px;
        font-weight: 300;
        white-space: nowrap;
    }



  
}

@media screen and (max-width:599px) {



    .woundHomeBlock {
        padding: 30px;

    }

    .woundHomeBlock .wtextwrapper h2 {
        color: rgb(11, 226, 229);
        margin-bottom: 10px;
        font-size: 26px;
        line-height: 30px;
        font-weight: 700;
        white-space: normal;
        
    }

    .wtextwrapper p {
        font-size: 20px;
        color: white;
        font-weight: 600;
        line-height: 33px;
        
    }

    .woundHomeBlock .wtextwrapper h3 {
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 20px;
        color: #fff000;
        line-height: 34px;
        margin-top: 20px;
        white-space: normal;
    }


    .wound_list_wrap p {
        display: flex;
        font-size: 18px;
        font-weight: 300;
        white-space: normal;
    }


  .wound_img_wrap img{
    height: 200px;
  }
  
}


@media screen and (max-width:399px) {



    .woundHomeBlock {
        padding: 20px;

    }

    .woundHomeBlock .wtextwrapper h2 {
        color: rgb(11, 226, 229);
        margin-bottom: 10px;
        font-size: 22px;
        line-height: 30px;
        font-weight: 700;
        white-space: normal;
        
    }

    .wtextwrapper p {
        font-size: 17px;
        color: white;
        font-weight: 600;
        line-height: 30px;
        
    }

    .woundHomeBlock .wtextwrapper h3 {
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 18px;
        color: #fff000;
        line-height: 25px;
        margin-top: 20px;
        white-space: normal;
    }


    .wound_list_wrap p {
        display: flex;
        font-size: 16px;
        font-weight: 300;
        white-space: normal;
    }


  .wound_img_wrap img{
    height: 200px;
  }
  
}