.derm_main_wrap{
    box-sizing: border-box;
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/dermytbg.webp");
    background-size: cover;
    background-repeat: no-repeat;
 
}

.derm_wrap{
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/dermytimg.webp");
    background-repeat: no-repeat;
    position: relative;
    padding-left: 960px;
    background-position: left;
    background-size: auto 100%;
  
}
.derm_wrap img{
   width: 320px;
   position: absolute; 
   bottom: 10px;
   left: 520px;
}

.derm_content_head{
    padding-top: 35px;
}

.derm_content_head h1{
    font-size: 42px;
    
    
}

.derm_content_head h2{
    font-size: 35px;
    color: rgb(10,29,113);
    font-weight: 600;
    
}

.derm_content_head p{
    font-size: 25px;
    
}

.derm_content_wrap h3{
    box-sizing: border-box;
    background-color:rgb(10,29,113) ;
    color: white;
    font-size: 25px;
    font-weight: 500;
    padding: 10px 0 10px 15px;
    border-radius: 10px;
    width: 350px;
    margin-top: 30px;
}


.derm_list_wrap{
    display: flex;
    position: relative;
    left: -40px;
    padding-bottom: 30px;
}

.derm_list_wrap ul li{
    list-style: none;
    font-size: 18px;
    font-weight: 600;
    padding-left: 40px;
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/dcircle.png");
    background-repeat: no-repeat;
    background-position: left;
    margin-top: 10px;
}
.derm_list_wrap ul ul{
    padding-left: 20px;
}

.derm_list_wrap ul ul li {
    background-image: none;
    background-image: url("https://all-frontend-assets.s3.amazonaws.com/health_and_pain_care_meta/meta_images/ddisk.png");
}

@media only screen and (max-width: 1700px){
    .derm_wrap{
        padding-left: 876px;
        background-position-x: -96px;
    }
}
@media only screen and (max-width: 1612px){
    .derm_wrap{
        padding-left: 816px;
    background-position-x: -155px;
    }
    .derm_wrap img{
        left: 400px;
    }
}
@media only screen and (max-width: 1552px){
    .derm_content_wrap ul{
        padding: 0;
    }
    .derm_wrap{
       
    background-position-x: -180px;
    }
    .derm_list_wrap{
        left: 0;
    }
}
@media only screen and (max-width: 1456px){
    .derm_list_wrap{
        flex-direction: column;
    }
    .derm_wrap {
        background-position-x: -388px;
        padding-left: 817px;
    }
}
@media only screen and (max-width: 1420px){
    .derm_wrap {
        background-position-x: -498px;
        padding-left: 718px;
    }
    .derm_wrap img {
        left: 304px;
    }
}
@media only screen and (max-width: 1320px){
    .derm_content_head h2{
        font-size: 30px;
    }
}
@media only screen and (max-width: 1240px){
    .derm_wrap{
        padding-left: 0;
        background-size: 100% auto;
        background-position: top;
    }
    .derm_list_wrap{
        flex-direction: row;
    }
    .derm_content_wrap{
        padding-top: 76%;
        padding-left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .derm_content_head , .derm_content_wrap h3,.derm_list_wrap{
       
        text-align: left;
        justify-content: space-between;
    }
    .right_list{
        padding-right: 40px;
    }
    .derm_wrap img{
        display: none;
    }
    .derm_content_wrap ul {
        padding: 0;
        text-align: start;
        
    }
}
@media only screen and (max-width:899px){
    .derm_list_wrap ul li{
        font-size: 14px;
            }

}

@media only screen and (max-width:755px){
    .derm_list_wrap{
        flex-direction: column;
        padding-right: 20px;
    }
    .derm_list_wrap ul li{
        font-size: 23px;
            }

}
@media only screen and (max-width:630px){
    .abbaner_block2 .textwrapper h2{
        font-size: 39px;
    }
}
@media only screen and (max-width:400px){
    .derm_content_head h1 {
        font-size: 36px;
    }
    .derm_content_head h2 {
        font-size: 25px;
    }
    .derm_content_head p {
        font-size: 20px;
    }
    .derm_content_wrap h3{
        font-size: 20px;
        width: 300px
    }
    .derm_list_wrap ul li{
        font-size: 15px;
    }
    .derm_content_wrap ul{
        padding-left: 0;
    }
    .derm_content_wrap{
       padding-left: 10px;
    }
}
