.Healthriskanalysis_banner .banner_con_text1 h1{
font-size: 76px;
    line-height: 80px;
}
.Healthriskanalysis_banner .banner_con_text1 h1 span{
    font-size: 49px;
    line-height: 55px;
}
.Healthriskanalysis_banner .banner_con{
background-image:url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/Healthriskanalysis_image1.webp);
    padding: 116px 0 127px 0;
    background-position: right bottom;
    background-size: auto 100%;
}
.common_banner_sec.Healthriskanalysis_banner .banner_wrpr_bg1{
    background-position: left 80px bottom -38px, top left 26%,top 100% left -40000px;
}


  
.Healthriskanalysis_block2{
    background-image:url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/Healthriskanalysis_bg1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.Healthriskanalysis_block2_subwrapper {
    position: relative;
    padding: 80px 0;
    padding-left: 530px;
}
.Healthriskanalysis_block2_subwrapper .abimage{
    position: absolute;
    left: -150px;
    bottom: 0;
    display: block;
     max-width: 100%;
}
.Healthriskanalysis_block2 .textwrapper h2, .Healthriskanalysis_block2 .textwrapper h3, .Healthriskanalysis_block2 .textwrapper p{color: #fff;}
.Healthriskanalysis_block2 .textwrapper h3{font-size: 25px;margin-bottom: 25px;font-weight: var(--font-medium);}

.Healthriskanalysis_block3{
    background-color: #0a1f72;
    position: relative;
    padding: 80px 0;
    padding-bottom: 150px;
}
.Healthriskanalysis_block3 .abimage{
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    max-width: 100%;
}

.Healthriskanalysis_block3 .textwrapper{
       padding-right: 840px;
}

.Healthriskanalysis_block3 .textwrapper h2, .Healthriskanalysis_block3 .textwrapper h3, .Healthriskanalysis_block3 .textwrapper p{color: #fff;}
.Healthriskanalysis_block3 .textwrapper h3{font-size: 25px;margin-bottom: 10px;font-weight: var(--font-medium);}
.Healthriskanalysis_block3 .block_listwrapper p{
    font-weight: var(--font-medium);margin-bottom: 0;
    font-size: 19px;
    line-height: 22px;
    padding: 9px 0;
    padding-left: 30px;
}
.Healthriskanalysis_block3 .block_listwrapper p::after{top: 10px;}
.Healthriskanalysis_block3 .textwrapper a{color: #10daff;font-size: 20px;padding-left: 30px;text-decoration: none;}
.greenbar_left h2{
    font-size: 43px; color: #fff;font-weight: var(--font-medium);
        display: flex;
    align-items: center;
    text-shadow: 0 0 3px rgba(0,0,0,0.3);
}
.greenbar_left h2 span{font-size: 55px; color: #0a1f71;font-weight: var(--font-semibold);margin: 0 35px;text-shadow:none;}

.green_bar {
    background: rgb(29,188,231);
    background: linear-gradient(83deg, rgba(29,188,231,1) 0%, rgba(27,194,57,1) 100%);
    padding: 15px;
    margin: 0 25px;
    position: relative;
    z-index: 1;
     margin-top: -87px;
    margin-bottom: -87px;
}
.green_bar_subwrapper{
    background-color: rgba(255, 255, 255, 0.4);
    padding: 20px 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
}

.Healthriskanalysis_block4 {
    padding-top: 110px;
    padding-bottom: 110px;
    background-color: #e9f9fa;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/HEALTH-ANDPAIN-CARE/images/service_bg3.png);
    background-repeat: no-repeat;
    background-position: right top;
}
.Healthriskanalysis_block4_subblock2{
    position: relative;
}
.Healthriskanalysis_block4_subblock2 .abimage {
    position: absolute;
    left: -220px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    max-width: 100%;
}
.Healthriskanalysis_block4_right{
    padding-left: 670px;
}
.Healthriskanalysis_block4_subblock2 .textwrapper h2{margin-bottom: 15px;}
.Healthriskanalysis_block4_subblock2 .block_listwrapper p {font-size: 18px;line-height: 24px;display: inline-block; width: 94%;}
.Healthriskanalysis_block4_subblock2 .block_listwrapper p::after {top: 10px;}
.list1 .block_listwrapper{column-count: 3;}
.list2 .block_listwrapper{display: flex;}
.list2 .block_listwrapper p:nth-child(1){width: 30%;}
.list2 .block_listwrapper p:nth-child(2){width: 30.5%;}
.list2 .block_listwrapper p:nth-child(3){width: 15%;}

.list3 .block_listwrapper{column-count: 3;}

.list4 .block_listwrapper p:nth-child(1){width: 30.5%;}
.list4 .block_listwrapper{display: flex;}


.list5 .block_listwrapper{display: flex;}
.list5 .block_listwrapper p:nth-child(1){width: 17%;}
.list5 .block_listwrapper p:nth-child(2){width: 24%;}
.list5 .block_listwrapper p:nth-child(3){width: 13%;}

.list6 .block_listwrapper{display: flex;}
.list6 .block_listwrapper p:nth-child(1){width: 17%;}
.list6 .block_listwrapper p:nth-child(2){width: 8%;}
.list6 .block_listwrapper p:nth-child(3){width: 29%;}

.list7 .block_listwrapper{display: flex;}
.list7 .block_listwrapper p:nth-child(1){width: 17%;}
.list7 .block_listwrapper p:nth-child(2){width: 40.5%;}

.list8 .block_listwrapper{display: flex;flex-wrap: wrap;}
.list8 .block_listwrapper p{margin-right: 20px;}


.list9 .block_listwrapper{display: flex;flex-wrap: wrap;}
.list9 .block_listwrapper p{width: 20%;}
.list9 .block_listwrapper p:nth-child(2){width: 20%;}
.list9 .block_listwrapper p:nth-child(3){width: 48%;}
.list9 .block_listwrapper p:nth-child(4){width: 20%;}
.list9 .block_listwrapper p:nth-child(5){width: 20%;}
.list9 .block_listwrapper p:nth-child(6){width: 48%;}
.list9 .block_listwrapper p:nth-child(7){width: 20%;}

.list10 .block_listwrapper{display: flex;flex-wrap: wrap;}
.list10 .block_listwrapper p:nth-child(odd){width: 54%;}
.list10.sublist h2, .list11.sublist h2{white-space: nowrap;}

.list11 .block_listwrapper{column-count: 2;}

.sublist h2{
    font-size: 30px;
    line-height: 33px;
    font-weight: var(--font-semibold);
    color: #000;
    margin: 12px 0;
}
.Healthriskanalysis_block4_subblock1 .textwrapper p{
        font-size: 25px;
    line-height: 30px;
    margin-bottom: 25px;
        padding-right: 120px;
}


.Healthriskanalysis_block5{
    background: rgb(185,230,255);
    background: linear-gradient(90deg, rgba(185,230,255,1) 0%, rgba(228,245,255,1) 100%);
    padding: 80px 0;
    padding-top: 120px;
}
.Healthriskanalysis_block5_subwrapper{position: relative;}

.Healthriskanalysis_block5_subwrapper .abimage{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.Healthriskanalysis_block5_subwrapper .textwrapper{
    padding-right: 754px;
}
.Healthriskanalysis_block5 .textwrapper p span{font-weight: 600;color: #000;}
.Healthriskanalysis_block4 .textwrapper p span{font-weight: 600;color: #000;}
.coloumn_count{column-count: 2;margin-bottom: 15px;}
.bold_p {margin-bottom: 15px;}
.bold_p p{font-weight: 600;}
.bold_p a {color: #0500ff;text-decoration: none;}

@media only screen and (max-width: 1840px) {
.list10.sublist h2,.list11.sublist h2{white-space:inherit}
.sublist h2{font-size:24px;line-height:25px}
.Healthriskanalysis_block4_right{padding-left:740px}
.list7 .block_listwrapper p:nth-child(2){width:41.5%}
}
@media only screen and (max-width: 1740px) {
.greenbar_left h2 span {
    font-size: 45px;
}
.greenbar_left h2 {
    font-size: 38px;}
    .Healthriskanalysis_block5_subwrapper .abimage{right: -120px;}
    .Healthriskanalysis_block5{overflow: hidden;}
}
@media only screen and (max-width: 1700px) {
 .Healthriskanalysis_block4_subblock2 .abimage{left:-290px}
}
@media only screen and (max-width: 1600px) {
.Healthriskanalysis_banner .banner_con{
        background-position: right -100px bottom;
}
.Healthriskanalysis_banner .banner_con_text1 h1 {
    font-size: 56px;
    line-height: 60px;
    width: 70%;
}
}
@media only screen and (max-width: 1580px) {
.Healthriskanalysis_block4_right{
    padding-left: 0;
    width: 720px;
    margin: 0 0 0 auto;
}
.Healthriskanalysis_block4_subblock2 .block_listwrapper p{font-weight: var(--font-medium);}
.list4 .block_listwrapper p:nth-child(1){width:30%}
.list2 .block_listwrapper p:nth-child(2){width:30%}
.list7 .block_listwrapper p:nth-child(2){width:41%}
.list9 .block_listwrapper p:nth-child(6){width:46%}
.list9 .block_listwrapper p:nth-child(3){width:40%}
.list9 .block_listwrapper p:nth-child(2){width:18%}
.list9 .block_listwrapper p:nth-child(1){width:22%}
.list9 .block_listwrapper p:nth-child(4){width:22%}
.list9 .block_listwrapper p:nth-child(5){width:18%}
.Healthriskanalysis_block4_subblock2{overflow: hidden;}
}
@media only screen and (max-width: 1560px) {
.Healthriskanalysis_block3 .abimage{right:-130px}
.Healthriskanalysis_block3{overflow: hidden;}
.Healthriskanalysis_block3 .textwrapper {
    padding-right: 740px;
}
}
@media only screen and (max-width: 1540px) {
.Healthriskanalysis_block4_subblock2 .abimage{left:-327px}
.greenbar_left h2 span {
    font-size: 38px;
}
.greenbar_left h2 {
    font-size: 34px;
}
}
@media only screen and (max-width: 1500px) {
.Healthriskanalysis_block4_subblock2 .abimage {
    left: -477px;
}
.Healthriskanalysis_block4_right{
    margin: 0;
    margin-left: 610px;
}
}
@media only screen and (max-width: 1440px) {
.Healthriskanalysis_block5_subwrapper .abimage {
    right: -220px;
}
.Healthriskanalysis_block5_subwrapper .textwrapper {
    padding-right: 630px;
}
}
@media only screen and (max-width: 1420px) {
.Healthriskanalysis_banner .banner_con_text1 h1 {
    width: 58%;
}
.common_banner_sec.Healthriskanalysis_banner .banner_wrpr_bg1 {
    background-position: left 480px bottom -18px, top left 26%,top 100% left -40000px;
}
.greenbar_left h2 span {
    font-size: 32px;
    margin: 0 10px;
}
.greenbar_left h2 {
    font-size: 28px;
}
.greenbar_left h2 img{width: 150px;}
.greenbar_left h2 img {
    width: 55px;
    margin-left: 90px;
}
}
@media only screen and (max-width: 1380px) {
.Healthriskanalysis_block4_right{
    margin: 0 0 0 auto;
}
}
@media only screen and (max-width: 1360px) {
.Healthriskanalysis_block4_right{width:650px}
.sublist h2{font-size:21px}
.Healthriskanalysis_block4_subblock2 .block_listwrapper p{font-size: 14px;}
}
@media only screen and (max-width: 1280px) {
.common_banner_sec.Healthriskanalysis_banner .banner_wrpr_bg1 {
    background-position: left 380px bottom -18px, top left 26%,top 100% left -40000px;
}
.Healthriskanalysis_block2_subwrapper{
        padding-left: 490px;
}

.greenbar_left h2 img {
    margin-left: 30px;
}

.Healthriskanalysis_banner .banner_con_text1 h1 {
    width: auto;
}
.Healthriskanalysis_banner .banner_con_text1 h1, .Healthriskanalysis_banner .banner_con_text1 h1 span {
    font-size: 32px;
    line-height: 41px;
}
.Healthriskanalysis_block4_subblock2 .abimage {
    left: -535px;
}
}

@media only screen and (max-width: 1199px) {
    .Healthriskanalysis_banner .banner{padding-bottom: 15px;}
 .Healthriskanalysis_block4_subblock2 .abimage{left:-460px;width:79%}
    .Healthriskanalysis_banner .banner_con {
    background-position: right top;
    padding: 0;
    background-size: 111%;
    padding-top: 50%;
}
.Healthriskanalysis_block2_subwrapper {
    padding: 30px 0;
}
.Healthriskanalysis_block2_subwrapper .abimage{display: none;}
.Healthriskanalysis_block3 .abimage {
    right: inherit;
    position: inherit;
    top: inherit;
    width: 100%;
    margin-bottom: 30px;
}
.Healthriskanalysis_block3 .textwrapper {
    padding-right: 0;
}
.Healthriskanalysis_block3{padding: 0;padding-bottom: 30px;}
.green_bar{margin: 0;}
.Healthriskanalysis_block4_subblock2 .abimage{left:inherit;width:100%;position:inherit;transform:inherit;top:inherit;margin-bottom:30px}
.Healthriskanalysis_block4{padding: 30px 0;}
.Healthriskanalysis_block4_right {
    width: auto;
}
.Healthriskanalysis_block4_subblock2 .block_listwrapper {
    display: block !important;
    column-count: 3 !important;
}
/* .Healthriskanalysis_block4_subblock2 .block_listwrapper p{    
    width: auto !important;

} */
.Healthriskanalysis_block5_subwrapper .abimage {
    right: inherit;
    position: inherit;
    top: inherit;
    transform: inherit;
    width: 100%;
    margin-bottom: 30px;
}
.Healthriskanalysis_block5_subwrapper .textwrapper {
    padding-right: 0;
    
}
.Healthriskanalysis_block5{padding: 0;padding-bottom: 30px;}
.green_bar_subwrapper {
    flex-direction: column;
    align-items: center;
}
.greenbar_left h2 img{display: none;}
.greenbar_left h2{margin-bottom: 15px;display: block;text-align: center;}
.Healthriskanalysis_block4_subblock1 .textwrapper p{    padding-right: 0;    font-size: 18px;
    line-height: 23px;}
.Healthriskanalysis_block4_subblock2 .textwrapper h2 {
        margin-bottom: 10px;
    }    
}
@media only screen and (max-width: 991px) {
.greenbar_left h2 span{display: block;}
.green_bar_subwrapper{padding: 15px;}
}
@media only screen and (max-width: 767px) {
    .Healthriskanalysis_block3 {
        padding: 0;
        padding-bottom: 15px;
    }
    .Healthriskanalysis_block2_subwrapper {
        padding: 10px 0;
    }
    .Healthriskanalysis_block3 .abimage{margin-bottom: 15px;}
    .Healthriskanalysis_block2 .textwrapper h2, .Healthriskanalysis_block2 .textwrapper h3, .Healthriskanalysis_block2 .textwrapper p {margin-bottom: 15px;}    
.Healthriskanalysis_banner .banner_con_text {padding-bottom: 15px;padding: 10px;}   
.Healthriskanalysis_block4_subblock2 .block_listwrapper {
    display: block !important;
    column-count: 2 !important;
}
.Healthriskanalysis_block4 {
    padding: 10px 0;
}
.Healthriskanalysis_block4_subblock1 .textwrapper p{
    margin-bottom: 14px;
}
.Healthriskanalysis_block4_subblock2 .abimage {
    margin-bottom: 14px;
}
.Healthriskanalysis_block4_subblock2 .block_listwrapper p::after{
    height: 10px;
    width: 10px;
    top: 11px;
}
.sublist h2{
    margin:8px 0;
}
.Healthriskanalysis_block4_subblock2 .block_listwrapper p{padding-left: 24px;}
.Healthriskanalysis_block5 .textwrapper p {margin-bottom: 15px;}
.Healthriskanalysis_block5 {padding-bottom: 15px;}
.Healthriskanalysis_block5_subwrapper .abimage{margin-bottom: 5px;}
.Healthriskanalysis_block5 .textwrapper p:last-child {margin-bottom: 0;}
.Healthriskanalysis_block2 .textwrapper p:last-child{margin-bottom: 5px;}
.Healthriskanalysis_block3 .textwrapper h2{
    margin-bottom: 15px;
}
.Healthriskanalysis_block3 .textwrapper p {
    margin-bottom: 5px;
}
}

@media only screen and (max-width: 520px) {
.Healthriskanalysis_block4_subblock2 .block_listwrapper {
    display: block !important;
    column-count: 1 !important;
}
}
@media only screen and (max-width: 480px) {
/* .Healthriskanalysis_block2_subwrapper {
    padding: 20px 0;
    padding-bottom: 1px;
} */
/* .Healthriskanalysis_block3 .abimage{
        margin-bottom: 20px;
} */
}